import blog1 from "../../img/blogs/blog1.png";
import blog2 from "../../img/blogs/blog2.jpg";
import blog3 from "../../img/blogs/blog3.png";
import blog4 from "../../img/blogs/blog4.png";
import blog5 from "../../img/blogs/blog5.png";
import blog6 from "../../img/blogs/blog6.png";
import blog7 from "../../img/blogs/blog7.png";
import blog8 from "../../img/blogs/blog8.png";
import blog9 from "../../img/blogs/blog9.png";
import blog10 from "../../img/blogs/blog10.png";
import blog11 from "../../img/blogs/blog11.png";
import blog12 from "../../img/blogs/blog12.png";
import blog13 from "../../img/blogs/blog13.png";
import blog14 from "../../img/blogs/blog14.jpg";
import blog15 from "../../img/blogs/blog15.png";
import blog16 from "../../img/blogs/blog16.png";
import blog17 from "../../img/blogs/blog17.png";

export const blogList = [
    {
        id: 1,
        published_date: "9TH JUNE, 2020",
        published_by: "Brang",
        title: "Supporting the Australian community",
        category: "About Brang",
        mainImage: blog1,
        content: [
            "As part of providing support to the people who need the most during the current crisis, Brang will heavily subsidize the cost of delivery to support our community: this comprises of elderly people, aged care residentials, health workers, social workers who are providing essential services and are at the front-line.",
            "Promoting local Australian businesses by connecting directly to shoppers & subsidizing the shipping cost for them.",
            "Creating job opportunities for people looking for work. In the current crisis, Brang will provide additional revenue for drivers who have taken a 90% hit in the existing ride-sharing business.",
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 2,
        published_date: "June 09, 2020",
        published_by: "Brang",
        title: `About our drivers - "Brangers"`,
        category: "About Brang",
        mainImage: blog2,
        content: [
            "As part of our core business, drivers are independent contractors who utilize their own bikes, cars, vans or trucks to deliver. As part of Brang Technology platform the drivers will be incentivized for each delivery. In today’s ride sharing world, the drivers are already accustomed of pick-ups and drop-offs of people from one destination to the other. Our platform will be leveraging the ride-sharing market (initial phase invites only) by connecting the ecommerce orders to drivers. This will provide additional source of revenue to the drivers to sustain in the current cutthroat environment. The process of becoming a Branger is very robust and straight forward. We have a defined checklist which all drivers must go through to get a Branger badge."
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 3,
        published_date: "10TH JUNE, 2020 ",
        published_by: "Brang",
        title: `Our Opportunity To Build A Different Future - Covid 19`,
        category: "About Brang",
        mainImage: blog3,
        content: [
            "No one could have ever imagined a world we are living in today. 'Lockdown' sounds like bracing ourselves for a missile attack. We can’t see this powerful enemy that is turning our world upside down..but can do anything to get rid of it!"
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 4,
        published_date: "10TH JUNE, 2020 ",
        published_by: "Brang",
        title: `Brang - Modern day delivery`,
        category: "About Brang",
        mainImage: blog4,
        content: [
            "When so many of us were sitting at home dealing with adjusting to the new normals, work from homes and new routines ; we got busy rolling out an updated app, a website and a delivery service platform that understands and caters to everything you and your businesses need!",
            "In the 20th Century BC (Before Corona), deliveries were all about SPEED,COST And CONVENIENCE. However, the times have changed in some way now! SAFE, RELIABLE And CONTACTLESS DELIVERIES along with the previous three attributes are the need of the hour! Bridging the gap - here we come!",
            "WHAT DO WE DO!?- Very Briefly :)",
            "+ Bring Things From Them And Deliver To You / Bring Things From You And Deliver To Them (No Strings) Throughout The Day 24 x 7 Within The City",
            "+ Deliver Cakes /Flowers/Medicines/Grocery/Meats/Furniture/Electronics/Food (You name it and we do it ) from your business houses to your customers",
            "+Add value to your deliveries by - Significant Cost Savings/ Online Invoicing/ Real Time GPS Tracking/ Contactless Deliveries/Service Reliability/Outstanding Technologically Driven Customer Support System",
            "+ Help You Build An Online Presence (Websites/App)",
            "HOW?!",
            "Team Brang and our Brangers (As We Fondly Call Them) will be happy to help!!",
            "+ Dowload Our App",
            "+ Call Us At 13000Brang",
            "+ Get In Touch With Our Sales Reps",
            "+ Mail Us on connect@brang.com.au or visit our website www.brang.com.au",
            "We are here to stay! So JUST BRANG IT ON!! Cheers!! 🥂",
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 5,
        published_date: "August 09, 2020",
        published_by: "Brang",
        title: `Welcome To The New Reality: Businesses Are More Or Less Digital And Products Are Now Delivered, 7 Days A Week,24x7`,
        category: "About Brang",
        mainImage: blog5,
        content: [
            "Web sales are up significantly this year as the widespread coronavirus still keeps many shoppers at home and away from physical stores. Ace sellers, consultants and vendors are now working on how they can revise their strategies in light of the pandemic, to sustain and sail through these times. And we have decided to help.",
            "Suddenly , there is so much to worry about. When it comes to health- both physical and mental. There's some uncertainty around and the last thing anyone would want to worry about is Logistics. As people become averse to stepping out, this should give a fillip to e-commerce and we are ready for that. Earlier people drove themselves to things and now things need to be driven to people.",
            "The way the uncertainty and the dangerous approach of the virus stares everyone in the face, its a real luxury to sit back in the comfort of your living rooms and with the click of few buttons have all you like on your table. Our brangers are at your service and we look at this as an opportunity to grow as well as serve our community in these dire circumstances.",
            "The concept is simple, we are delivering. Be it from stores to you, from you to stores, from friends to you , from you to friends , from home to office , from office to home, from cake shops, meat shops, groceries, chemists, furniture stores, bakeries. You name it and we do it. We are making lives easier for retailers as well as customers. We are available and we are delivering. We are an All-Time Available Logistics Company. With easy to use technologically advanced app, we have just made it simple for everyone to avail our services.",
            "Times are challenging. Stay safe.",
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 6,
        published_date: "August 10, 2020",
        published_by: "Brang",
        title: `Some Beautiful Paths Cant Be Discovered Until We Get Lost' Go Out! Face Your Fears! Scare Yourself!`,
        category: "About Brang",
        mainImage: blog6,
        content: [
            "Starting a business is hard. Really hard.",
            "Businesses are 90 percent about the money and 10 percent about the other things. But start ups are 90 percent about the passion of starting from scratch and making it count!! It’s a small idea and a small set of motivated people who take it up and take it places!",
            "When you’re first starting out .. you need all the help you can get. You look for resources and find articles from Entrepreneurs, Forbes, Harvard Business Review , Economic Times etc. You pick up words that make sense to you and apply them to your organizations, hoping the strings produce a music and a pleasant one! You tend to enjoy the journey, because the destination is a mirage.",
            "You feel overwhelmed because the ideas are just too high-level. You don’t take meaningful action. You just don’t have time to cut through all the fluff. You feel direction less , in pieces. At times , you pick yourself up and push yourself to keep going..!! You stop sometimes to breathe, analyze and question yourself. Your journey might not be perfect, but its yours! When your inspiration and desire to create something worthy is stronger than your fears, then you are on the right track. Life is either a daring adventure or nothing at all..",
            "BRANG is a story of pepped up and self motivated team, who have boarded a train for the alps. . So see you in the clouds!!",
            "Brang is a delivery platform catering to the people and businesses across Melbourne . Targeting Hyper-local deliveries across Melbourne.",
            "PS: Coming up in the next blog is the inspiring story of the men behind the scenes, who put grave meaning to all the above words. They have been there and done that. So stay tuned! Because there's so much to learn!",
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 7,
        published_date: "September 18, 2020",
        published_by: "Madhup Mudiraj",
        title: `Extremely Excited To Welcome Our New Advisors On Board !!`,
        category: "About Brang",
        mainImage: blog7,
        content: [
            "The Brang Team is excited to announce its association with 2 legendary tech stalwarts - Matthew Perry and David Waite, who will now be joining our Advisory Board. We believe that their presence and their input will be an asset to our company and we can't wait to see what we can accomplish together!!",
            "The Advisory Board serves a vital function for Discoverr.AI. Their primary role is to provide valuable advice and recommendations for business expansion and help the team stay focused on the main priorities.",
            "Brang provides a simple delivery platform for consumers to get their products delivered on the same day. Our platform addresses the current gaps in the market and our vision is to make deliveries hassle-free, cost eﬀective and fast.",
            "Thank you, Matt & David, for sharing our vision and we look forward to a successful partnership."
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 8,
        published_date: "September 18, 2020",
        published_by: "Brang",
        title: `Introducing Our New Advisor - David Waite`,
        category: "About Brang",
        mainImage: blog8,
        content: [
            "David is a Senior Executive with demonstrated experience of delivering growth through Business Development Strategy and execution with key skills in Leadership and Customer Engagement.",
            "He currently leads a Global Marketing Automation Company (SAAS) and has previous experience across eLearning, Online Media, Supply Chain/Logistics and Professional Services. He is a valued Executive Level contributor to Business Strategy, Operational Excellence and driving results through Staff Engagement. David is respected by peers and team for his leadership through challenging change, economic conditions and now Pandemics!",
            "I have had the good fortune to experience great mentors and leaders throughout my career and the clear advice they have all shared in common when assessing business decisions (opportunities, projects, investments etc) is to focus on the quality of the people involved.",
            "Business success is highly co-related to the quality of the team employed to deliver the result. He will be a part of Brang’s advisor team, providing insights and guidance which any start-up can gain from. Brang is thrilled to have someone with years of experience and expertise to share, through which a lot can be learned and applied.",
            "Brang addresses a significant gap in the delivery market that major brand logistics providers have unsuccessfully been able to solve. The quality of the people and the significance of the delivery problem that Brang is solving is a journey I am keen to support."
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 9,
        published_date: "December 16, 2020",
        published_by: "Brang",
        title: `Introducing Our New Advisor - Matthew Perry`,
        category: "About Brang",
        mainImage: blog9,
        content: [
            "Matthew is a seasoned global executive, with over 20 years in global technology leadership roles around the world. He has worked in a range of industries; from architecture to manufacturing, pharmaceutical to FMCG. The small, mid and large sized teams that he has led have become extremely customer focused, innovative and relevant, and have grown both professionally and personally through each positive transformational journey. Now he will be providing his expertise as an advisor to a start-up company Brang. Brang focuses on one aim that nobody else in the business has offered till now, and that is get anything anywhere at any time. Matthew strongly believes in the power of an enabled and empowered organisation; delivering a state of continuous improvement, innovation and commercial value.",
            "He saw the potential in Brang, and understands that the company is trying to achieve something new and unusual than others.",
            "The Discoverr.AI team had identified a number of market opportunities, built astute business cases and conceptualised solutions through design thinking exercises.",
            "BRANG is the first product to travel through the Discoverr.AI innovation factory into production. BRANG fills a significant and growing gap in our needs. A gap that the big, monolithic players are unable to fulfill and the shop-specific providers are unwilling to offer.",
            "Brang’s main motive is to deliver anything from anywhere to anyone, fast. Currently, The team has built the platform and are very successfully piloting the solution in the Melbourne metropolitan region. It is a privilege to have Matthew Perry as an advisor, particularly during this early phase.",
            "You need it now, you want it shipped now…Just BRANG it"
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 10,
        published_date: "January 26, 2021",
        published_by: "Brang",
        title: `Looking For A Responsible Hassle- Free Delivery Service`,
        category: "About Brang",
        mainImage: blog10,
        content: [
            "The Postal Industry in Australia is undergoing a rapid change. More and more Australians are now shopping online from the comfort of their homes, while at work and even impulse buying using mobile services. A survey shows that the complains concerning delivery issues continues to be a big challenge for all major operators including Australia Post.",
            "During the pandemic and the festive season that fell during the pandemic – Australia Post said that it's network has been stretched to it’s limit. Traffic to the website also had almost doubled since the pandemic. While this is a great thing to happen for Australia Post- it came along with it’s share of problems!! So much so that they had to release a new audio campaign to explain the delays!!",
            "A delay, a mis-delivery or missed delivery can create severe and lasting consequences for an individual or a company. That’s why you must be able to trust that your same-day delivery service will perform with complete accuracy and no unexpected delays.",
            "The biggest fear businesses face when entrusting a package to a courier service is that it will disappear without a trace. If your courier service doesn’t employ a proper tracking system (surprisingly, not all of them do!), once the driver picks up a package there’s no way to know where it is, what time it got delivered, if it got delivered, who signed for it – too many things can go wrong. Be it legal documents, cakes-flowers that are meant for surprises, crucial medication, or a time-sensitive contract bid, if your courier service messes up, you are the one who suffers.",
            "A store or a seller that accepts payments for products must supply them",
            "- Within any time-frame they have indicated",
            "- At all times to miss out on business",
            "- Without digging a hole in their pockets by having to keep all time drivers to do the job",
            "- Safely with all safety precautions and without damaging the products",
            "HOW ARE WE DIFFERENT?",
            "BRANG has developed multi-check systems to guarantee on-time delivery with accuracy trusted by pharmacies, florists, bakers, grocers and businesses of all sizes.",
            "Our App Is Programmed to pick up the correct addresses and suggests for any mistakes or incorrect addresses",
            "We organise driver training programs for safe and smooth deliveries, especially during the pandemic",
            "We Provide our drivers with a standardised check list of relevant steps to follow during the delivery process, thereby collecting enough evidence of delivery including pictures at delivery locations and of deliverables at pick up and drop, minimalizing the chances of any mistakes",
            "We can live track deliveries giving an insight of driver’s exact location and ETA",
            "We monitor and rate driver performances to ensure compliance of procedures",
            "We drop undelivered goods back to the store",
            "We are completely covered under Insurance for loss and/or damage",
            "24x7 Support Available , reverting with 2-4 hours on receipt of email",
            "We are open to learning and make continuous improvements and keep upgrading technology for the benefit of our clients",
            "At BRANG, we utilize proprietary state-of-the-art software, so we always know the exact location of your package. From the moment you call in or place an order online, our system assigns a tracking number. Attached to that number are all the essentials: client name, where we’re picking up, delivery address, any special notes (who can sign for it, whether it can be left at the front desk, etc.). This tracking number follows the driver wherever they go, so we know everybody who ever touches your package, from pick-up to delivery.",
            "The moment we have materials physically in hand, we email the client, telling you the package is en- route. While the package is in transit, we stay in contact with the driver through our application.",
            "When we deliver, we automatically send another message to the client as Proof of Delivery (POD). We stay on top of the process every step of the way. At BRANG we constantly update our systems with the newest technology available, putting us on equal footing with all major players!!",
            "DO YOU KNOW WHERE YOUR PACKAGE IS AT ALL TIMES? AT BRANG, THE ANSWER IS ALWAYS YES!)",
            "With competitive rates and state-of-the-art technology , BRANG exactly knows how to deliver. BOOK YOUR DELIVERIES NOW! Call us at 13000BRANG!",
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 11,
        published_date: "17TH FEBRUARY, 2021",
        published_by: "Brang",
        title: `Same-day Delivery – A Game Changer For Small Businesses In Australia!`,
        category: "About Brang",
        mainImage: blog11,
        content: [
            "Same-day delivery is the next evolutionary step in parcel logistics. In this concept, orders are delivered within a few hours after purchasing them, or in a chosen time window on the same day. Same-day fulfillment can change the trajectory of any ecommerce business.",
            "Imagine ordering a new pair of shoes or groceries for dinner in the morning and receiving the goods when you return home from work. Or imagine having a small home bakery or a popular retail FaceBook Page and to be able to deliver as and when the orders come!",
            "Waiting times in the reception of products is the great handicap of e-commerce today. However,the same day delivery makes it possible to reduce the impact of this disadvantage.",
            "Just how important is same-day shipping? Take a look at these stats: 64% of millennials are more likely to make an online purchase if same-day delivery is an option, 88% of consumers are willing to pay for same-day or faster delivery. Last minute holiday shoppers are 78% more likely to purchase gifts from retailers that offer same-day delivery and 71% of consumers are highly likely to recommend a retailer to others after purchasing a holiday gift and having it delivered the same day ,51% of ecommerce retailers are already offering same-day delivery by 2020, as compared to only 16% in 2016. That says a lot in itself.",
            "Businesses that offer same-day delivery make a commitment to going above and beyond for their customers. In turn, those customers are impressed. Businesses with same-day delivery typically see a decrease in returns and an increase in customer loyalty. Most folks have the desire to support small businesses over large corporations, but they often prefer big corporations because they offer same-day delivery. If your small business provides the same service as major retailers, you can draw in new customers who are looking to support local businesses with exceptional services.",
            "Same-day delivery has the potential to fundamentally change the way we shop and the way we sell.It integrates the convenience of online retail with the convenience of ordering from home,including incumbent logistics providers. Demand is expected to increase significantly given the compelling value proposition of same-day delivery for consumers.",
            "Consumers clearly attach a value to same-day delivery as there are many benefits of the same. Below are some of them:",
            "Improves Customer Satisfaction",
            "Reduce Friction In The Purchase Decision",
            "Helps Reduce Cart Abandonment Rates",
            "Competitive Advantage",
            "Online retailers are expected to benefit from reduced delivery time as almost immediate product access improves their position versus stationary retailers, and makes the greater choice, higher convenience and often lower prices of online shopping even more appealing.",
            "The world’s largest retailers, including Amazon and Walmart, are nurturing this vision and experimenting with innovative delivery concepts. The same day delivery is one of the most requested services and those famous for their customer orientation have triumphed with this mode of delivery. But can this really become a standard delivery option for the masses, or will it merely remain an option for a limited number of impatient consumers with deep pockets?",
            "However, there is still a general deficit of same day delivery providers and its advantages for consumers and companies in Australia. The reduction in delivery times will have a very positive impact on e-commerce. Not only that, the same day delivery in e-commerce brings added value to customers.",
            "With BRANG stepping in, retailers in Australia have a unique opportunity to combine their existing local infrastructure with an e-commerce channel to offer same-day delivery on a broad scale. A multichannel approach would enable them to win back customers who are becoming increasingly focused on online shopping.",
            "If your competitors do not offer this service, this is a perfect opportunity to gain a competitive advantage. It is possible that your commercial rivals will follow your example, but you will have a good margin of advantage. You have to be one step ahead of the competition! Always!",
            "If you are delivering B2B (business to business) or are just beginning your same-day delivery journey, partnering with an on-demand delivery service is the way to go. BRANG is an on-demand same-day delivery service that is reliable for big and small shipments alike. In addition to offering your customers lightning-fast turnaround, a delivery platform like BRANG takes the headache out of delivery logistics — you gain access to drivers and vehicles without the added costs and liability of going at it alone.",
            "Same-day delivery is a huge game-changer for small businesses. By delivering your products quickly, you are also delivering incredible above-and-beyond service, and your customers will take note.",
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 12,
        published_date: "17TH FEBRUARY, 2021",
        published_by: "Brang",
        title: `Courier Business Insights In Australia`,
        category: "About Brang",
        mainImage: blog12,
        content: [
            "The parcel delivery market in Australia is seeing an exponential growth backed by easy shipping, delivery and tracking parcels domestic and internationally. The seamless experience provided by many online marketplaces has driven consumers' expectations for convenience, price and availability of product from around the world, created potential through global e-commerce. This has caused an increase in competition within these industries",
            "B2B shipping is complicated. The wheels are set in motion long before you tell your customers that their order is being processed. The product delivery experience could be the foremost crucial phase in your consumer relations.",
            "Customers will not hesitate to take their business elsewhere if they are discontent at any stage. Whether or not your B2B is strictly brick and mortar or you are a growing e-commerce seller, an efficient shipping strategy can increase your efficiency, and your customers are going to be happy knowing they can count on you for a seamless delivery",
            "Establishing Goals:",
            "Your strategy ought to take into thought every side of product delivery, from packaging to your carrier, additionally to the result you'd like for your business and your customers.",
            "When you have specific goals in mind, you'll tailor your shipping strategy to understand the required effects. Of course, the foremost objective is to gift your customers a sleek delivery methodology.",
            "Same-Day Delivery:",
            "Running a business isn’t continually as simple as shop owners would need it to be. Fast inconveniences and wishes arise from time to time – furthermore as emergency deliveries with same-day delivery expectations. Having a same-day delivery alternative for packages, letters, or very important notices is very important for businesses.",
            "Contact-less Delivery:",
            "COVID 19 has made unprecedented changes in human behaviour. Although humans are social naturally, distancing is the new normal and we cannot predict when things will get back to how they were. Consumer expectations from retailers on home deliveries have changed drastically as a result of the impact of the pandemic. To drive seamless contact-less deliveries it's very important to embrace trendy contactless delivery methods and processes.",
            "Hyperlocal Deliveries:",
            "Hyperlocal delivery models provides a plethora of advantages to every customers and also the retailers. Hyperlocal delivery models provides them with a chance to understand visibility and optimize their sales. The deliveries are taken care of by the delivery partner of the aggregators. This technique creates pressure on retailers to boost their efficiency level and business standards. Hence, the consumers will expect a way higher quality of product or services at competitive rates.",
            "Multiple Delivery options:",
            "Every shopper expects to choose an alternative for delivery options. Your customers are no different. They require the freedom to choose on what works best for them that supports their specific needs.",
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 13,
        published_date: "November 18, 2021",
        published_by: "Brang",
        title: `The Emergence In Delivery Services`,
        category: "About Brang",
        mainImage: blog13,
        content: [
            "The corona virus pandemic has deeply affected our world since the last year. It's caused many shocking disruptions, accelerated some existing trends and created us to make some changes to how we tend to work and live.",
            "With the lockdown it became clear everybody couldn't simply get into a store, so it became a necessity to assist out local businesses /customers and deliver.",
            "One-third of the world population in lockdown require services that deliver groceries, medicines, ready food, and different essentials which have never been in higher demand.",
            "Of these essential categories, grocery has arguably emerged as the foremost essential. As lockdowns loomed, panicked customers stripped shelves vacant around the world.",
            "The past decade brought a boom within the world marketplace for delivery. On-demand delivery is the future for urban areas filled with young, wealthy, tech-savvy customers who have learned from services like Uber to expect instant service at the touch of a smartphone button.",
            "FACTORS TO CONSIDER WHILE SELECTING A DELIVERY SERVICE.",
            "As the delivery services have progressively become standard over the recent years, there’s a good selection of delivery services catering to the general public for nation-wide deliveries. Several businesses/people who are about to utilize the delivery service for the first time should check the various top-rated delivery service suppliers. Here are some necessary things to be check where selecting the most effective parcel delivery service.",
            "Speed",
            "Security and Safety",
            "Attitude Towards Care",
            "Areas covered",
            "Tracking",
            "Pricing",
            "Speed",
            "This is maybe one of the foremost vital and customary deciding factors when selecting between delivery services. As a result of urgency sometimes businesses need to send their parcels desperately on the same day within a few hours. Therefore, explore the average speed of delivery for every company, and what range of speeds they'll deliver at.",
            "Security and Safety",
            "The delivery service company must be safe and secure to confirm the protection of your parcel. People additionally send their confidential or non-public documents through delivery service. Thus, it's vital to envision the protection profile of the corporate whereas selecting the delivery service.",
            "Care towards packages",
            "If you’re shipping delicate things, fragile merchandise or something that must arrive in good condition, the approach on how your driver handles them is very important. Once making your choice, check that you discuss with them regarding what their approach is towards your deliveries – and browse their reviews too!",
            "Areas covered",
            "If your business is taking orders to be delivered to different locations across the country, you need to know that your delivery service will be able to fulfil those orders.",
            "Tracking",
            "Choose the delivery service provider that is providing the tracking via the web or mobile, so you'll be able to check the status of your packages in real-time.",
            "Pricing",
            "Always check the prices of packages from various services before selecting in order to save your hard cash. Many organizations charge a higher price than others with similar services and delivery timings.",
            "Brang is a one stop delivery platform that addresses all these issues, giving customers complete control of their deliveries in a very price effective approach. In fact rather than twenty four hours we pride ourselves to promise delivery within four hours. We tend to take further care concerning the safety as well as the package of our customers. We organise driver coaching programs for safe and smooth deliveries, particularly throughout the pandemic. We are able to live track deliveries giving an insight of driver’s exact location, ETA and 24x7 Support. We are a group of professionals making an attempt to resolve everyday issues using advanced technology.",
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 14,
        published_date: "16TH DECEMBER, 2021",
        published_by: "Brang",
        title: "Postal Delivery Text Scam Rise Ahead Of ...",
        category: "About Brang",
        mainImage: blog14,
        content: [
            "The crooks have targeted avid online shoppers and those awaiting Christmas presents in a heightened effort to steal money from Australians.",
            "Dodgy crooks have once again stepped up their efforts to squeeze their money from unsuspecting Australians using an annoying parcel delivery tactic.",
            "People eagerly awaiting SMS notifications for deliveries are being targeted, apparently more with online orders at an all-time high as Christmas approaches.",
            `Many delivery scams start with a text message or an email about delivering a package to your address. These messages often include a "tracking link" that you are urged to click in order to update your delivery or payment preferences. You might also get a voicemail message with a call-back number, or a "missed delivery" tag on your door with a number to call.`,
            "While these messages often look or sound legitimate, you should never click a link or call back the number from an unexpected delivery notice. Contact the delivery service or seller directly using a verified number or website.",
            `In some cases, a link may open a website that prompts you to enter personal information, or it may install malware on your phone or computer that can secretly steal personal information. The number you call back may be answered by a scam "operator" asking to verify your account information or the credit card number you used for a purchase. Other scam calls and texts may claim you need to pay a customs fee or tax before the delivery can be made.`,
            "National delivery companies are also providing information on their websites to help consumers avoid falling for package delivery scams.",
            "TIPS TO AVOID SCAMS",
            "Don’t respond to unexpected requests for money in return for delivery of a package, often with a sense of urgency.",
            "Don’t give out your personal and/or financial information.",
            "Don’t respond to links with misspelled or slightly altered website addresses.",
            "Don’t click links that are emailed or texted to you.",
            "Contact the shipping company directly with questions.",
            "Remember that imposter scams often illegally spoof phone numbers used in calls and texts to try to trick you into thinking that the number is from a legitimate company or even a government agency.",
            "If you receive any information about an unexpected package delivery, err on the side of caution. Follow the tips above to keep your information and finances secure.",
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 15,
        published_date: "1ST FEBRUARY, 2022",
        published_by: "Brang",
        title: "5 THINGS YOU MUST CONSIDER BEFORE HIRING A MEDICAL COURIER SERVICE",
        category: "About Brang",
        mainImage: blog15,
        content: [
            "As a medical service provider, you have countless things to worry about. Between patient care, staffing, maintaining clinical procedures, fiscal plans, and more! Making sure your samples and materials are delivered properly should be the least of your worries.",
            "Unfortunately, some medical delivery services do not take as much care as you deserve. There are many special considerations when delivering medical supplies that make it challenging to ensure the quality of service you expect.",
            "These 5 characteristics of a quality medical courier service will guide you to make the best choice for your company.",
            "1. HIPAA Compliance",
            "HIPAA enforces strict rules and regulations around what protected health information is accessible to drivers and shared with others. Communication in any form should be handled carefully and evaluated to ensure there is no compromised data.",
            "Breaching HIPAA laws whether intentionally or unintentionally can result in hefty fines – or even prosecution – for all responsible parties.",
            "2. Proper Equipment",
            "Proper tools and equipment required for delivery are essential to success and safety. It is necessary that all vehicles and related accessories are checked frequently and as up to date as possible.",
            "All equipment necessary for a delivery should be sanitized, stocked, and confirmed as “safe” before hitting the road.",
            "3. Reliable and On-Time Deliveries",
            "Receiving a delivery can sometimes mean life or death. Urgent and punctual delivery is critical in medical settings.",
            "Any medical courier service should be able to monitor proper delivery at the right time and place. Unlike mainstream delivery services, many courier services offer peace of mind with a delivery guarantee.",
            "4. Reliable Payment Process",
            "Payment should be the least of your worries when choosing a courier service. Unfortunately, this often is not the case. Faulty and unreliable payment processors may severely impact shipping times and delivery of goods.",
            "Choosing a courier service that has an effective payment system is often overlooked, but it can potentially ruin an otherwise successful delivery.",

        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 16,
        published_date: "February 11, 2022",
        published_by: "Brang",
        title: "Brang delivery service: the best same day delivery service",
        category: "About Brang",
        mainImage: blog16,
        content: [
            "No mall? No problem! While most of us have been staying home these days, online shopping has become more popular as a pastime for just about anyone. From finding the best deals on your favourite e-commerce sites to getting that sushi platter delivered right to your doorstep, shopping is a lot safer and more convenient when you're shopping through your phones or computers.",
            "But online shopping can come with a hefty price tag. On top of the price that you pay for the goods you're buying, shoppers' fees and shipping or delivery fees can cost just as much! Luckily, same day delivery services have become more affordable and accessible!",
            "One of the newest delivery apps in Australia, the Brang app offers delivery services you can always rely on. Here are some of the features of the app's same day delivery service we're sure you'll love!",
            "1. It's fast and easy!",
            "Just by booking on the Brang app, which takes mere seconds, one of our Branger will be on his way to pick up the package that you ordered and deliver it to you.",
            "Although the time it takes to get to you still depends on where the package is coming from and the traffic along the way, any delay shouldn't be too bad, and you'll still get your package within 4-hours!",
            "2. You can track your order real-time.",
            "Through the Brang app, you can check the location and status of the Branger handling your package live. The Branger will also message you for updates or notify you when he's arrived at the pick-up and drop-off locations.",
            "3. The risk of loss or damage isn't as high.",
            "One of the biggest worries of customers and sellers alike when it comes to shipping or delivery is that the products may get lost or damaged while in transit.",
            "With Brang's same day delivery service app, the chances of loss or damage are slim thanks to the live-tracking feature and quick delivery. We assure you our rider partners are well-trained and very careful when it comes to delivering your packages too!",
            "4. You get to choose the schedule for pick-up.",
            "When it comes to traditional shipping couriers, a huge challenge is not knowing when to expect your package to get picked up or when it would arrive. You get certain updates through tracking it on their website, but there's really no way of knowing the exact time.",
            "For our same day delivery services, it's all up to you! If you need an item picked up at a specific time, you just have to indicate it in the Brang app.",
            "5. You can save up a lot with the Brang app's discount voucher codes!",

        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },
    {
        id: 17,
        published_date: "7TH MARCH, 2023",
        published_by: "MADHUP MUDIRAJ",
        title: "Brang joins blockchain australia to innovate last mile delivery",
        category: "About Brang",
        mainImage: blog17,
        content: [
            "We are excited to announce that Brang has become a member of Blockchain Australia, the peak industry body for businesses implementing or evaluating blockchain or distributed ledger technology in Australia.",
            "At Brang, we are passionate about blockchain innovation in the last-mile delivery space, helping businesses develop strategies and capabilities to grow faster. We understand that logistics is the core foundation of business growth, and we are committed to providing innovative solutions to help businesses in Australia succeed.",
            "Our flagship product, BrangChain, is an application built on a blockchain foundation that provides an immutable, tamper-proof, decentralized distributed ledger, along with ample security features. BrangChain makes it possible to create the much-needed last-mile proof of delivery solution for businesses.",
            "With BrangChain, businesses can enjoy complete visibility and transparency over their delivery operations, ensuring that their customers receive their orders on time and in perfect condition. Our solution is particularly useful for businesses in the pharmacy and retail industries, where last-mile delivery is critical to their success.",
            "As a member of Blockchain Australia, we are committed to furthering the adoption of blockchain and distributed ledger technology in Australia. We believe that this technology has the potential to transform the way businesses operate, and we are excited to be part of this revolution.",
            "In conclusion, we are thrilled to be part of Blockchain Australia and look forward to working with other members to drive innovation and growth in the last-mile delivery space. Stay tuned for more exciting developments from Brang!",
        ],
        facebook_link: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        twitter_link: "https://twitter.com/intent/tweet?text=my share text&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75",
        linkedin_link: "http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fbrang.com.au%2Fblog-details%2FBrang-Joins-Blockchain-Australia-to-Innovate-Last-Mile-Delivery%2F75&title=Brang Joins Blockchain Australia to Innovate Last Mile Delivery"
    },

]