import { Box, Button, List, ListItem, Typography } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';

const BlogCategories = () => {
    return (
        <>
            <Box sx={{
                marginBottom: '20px',

            }}>
                <Box sx={{
                    '& input': {
                        padding: { xl: '0 30px 0 10px', lg: '0 30px 0 10px', md: 'inherit', sm: '0px 0px 0px 10px', xs: '0px 0px 0px 10px' },
                        outline: 'none',
                        border: '1px solid #00000033',
                        borderRadius: '5px',
                        color: '#495057',
                        marginBottom: "30px",
                        height: '40px',
                        maxWidth: '-webkit-fill-available',
                        width: '100%',
                    }
                }}>
                    <input placeholder='Search Blogs' />
                    <Button type='submit' sx={{
                        // padding: '8px',
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '0px',
                        backgroundColor: '#0046f7 !important',
                        marginLeft: '-65px',
                        height: "41px",
                        '& svg': {
                            fill: '#fff'
                        }
                    }}>
                        <SearchIcon />
                    </Button>
                </Box>
                <Typography sx={{
                    fontWeight: '600',
                    marginBottom: '15px',
                    fontSize: '18px',
                    position: 'relative',
                    paddingLeft: '15px',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        left: '0',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '5px',
                        height: '15px',
                        backgroundColor: '#0046f7',
                    }
                }}>CATEGORIES</Typography>
                <List sx={{
                    maxWidth: { xl: '250px', lg: '250px', md: '250px', sm: '250px', xs: '100%' },
                    width: '100%',
                    paddingTop: '0px',
                    '& li': {
                        paddingLeft: "0px",
                        borderBottom: '1px solid #eee',
                        cursor: 'pointer',
                        '&:hover': {
                            color: '#0046f7',
                        }
                    }
                }}>
                    <ListItem>All Blogs</ListItem>
                    <ListItem>About Brang</ListItem>
                    <ListItem>Covid 19</ListItem>
                    <ListItem>Brangers</ListItem>
                    <ListItem>Announcement</ListItem>
                </List>
            </Box>
            <Box sx={{
                marginBottom: '20px'
            }}>
                <Typography sx={{
                    fontWeight: '600',
                    marginBottom: '15px',
                    fontSize: '18px',
                    position: 'relative',
                    paddingLeft: '15px',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        left: '0',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '5px',
                        height: '15px',
                        backgroundColor: '#0046f7',
                    }
                }}>ARCHIVES</Typography>
                <List sx={{
                    maxWidth: { xl: '250px', lg: '250px', md: '250px', sm: '250px', xs: '100%' },
                    width: '100%',
                    paddingTop: '0px',
                    '& li': {
                        paddingLeft: "0px",
                        borderBottom: '1px solid #eee',
                        cursor: 'pointer',
                        '&:hover': {
                            color: '#0046f7',
                        }
                    }
                }}>
                    <ListItem>All Blogs</ListItem>
                    <ListItem>About Brang</ListItem>
                    <ListItem>Covid 19</ListItem>
                    <ListItem>Brangers</ListItem>
                    <ListItem>Announcement</ListItem>
                </List>
            </Box>
            <Box sx={{
                marginBottom: '20px'
            }}>
                <Typography sx={{
                    fontWeight: '600',
                    marginBottom: '15px',
                    fontSize: '18px',
                    position: 'relative',
                    paddingLeft: '15px',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        left: '0',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '5px',
                        height: '15px',
                        backgroundColor: '#0046f7',
                    }
                }}>SUBSCRIBE</Typography>
                <Typography sx={{
                    fontSize: "18px",
                    marginBottom: '15px'
                }}>BRANG NEWSLETTER</Typography>
                <Box sx={{
                    '& input': {
                        padding: '0 45px 0 15px',
                        padding: { xl: '0 45px 0 15px', lg: '0 45px 0 15px', md: 'inherit', sm: '0px 0px 0px 10px', xs: '0px 0px 0px 10px' },
                        height: { xl: '40px', lg: '40px', md: '40px', sm: '40px', xs: '40px' },
                        outline: 'none',
                        border: '1px solid #00000033',
                        borderRadius: '5px',
                        color: '#495057',
                        maxWidth: { xl: '-webkit-fill-available', lg: '-webkit-fill-available', md: '-webkit-fill-available', sm: '-webkit-fill-available', xs: '-webkit-fill-available' },
                        width: '100%',
                    }
                }}>
                    <input placeholder='Email' />
                </Box>
                <Button sx={{
                    backgroundColor: '#0046f7 !important',
                    color: "#fff",
                    textTransform: 'inherit',
                    marginTop: '15px',
                    fontSize: '16px'
                }}>Subscribe</Button>
            </Box>
        </>
    )
}

export default BlogCategories;
