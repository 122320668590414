import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const VerticalSlider = ({ data, leadingText }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();

    // Used to determine which items appear above the active item
    const halfwayIndex = Math.ceil(data?.length / 2);

    // Usd to determine the height/spacing of each item
    const itemHeight = 70;

    // Used to determine at what point an item is moved from the top to the bottom
    const shuffleThreshold = halfwayIndex * itemHeight;


    // Used to determine which items should be visible. this prevents the "ghosting" animation
    const visibleStyleThreshold = shuffleThreshold / 2;


    const determinePlacement = (itemIndex) => {
        // If these match, the item is active
        if (activeIndex === itemIndex) return 0;

        if (itemIndex >= halfwayIndex) {
            if (activeIndex > itemIndex - halfwayIndex) {
                return (itemIndex - activeIndex) * itemHeight;
            } else {
                return -(data?.length + activeIndex - itemIndex) * itemHeight;
            }
        }

        if (itemIndex > activeIndex) {
            return (itemIndex - activeIndex) * itemHeight;
        }

        if (itemIndex < activeIndex) {
            if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
                return (data?.length - (activeIndex - itemIndex)) * itemHeight;
            }
            return -(activeIndex - itemIndex) * itemHeight;
        }
    };

    useEffect(() => {
        const handleClick = (direction) => {
            setActiveIndex((prevIndex) => {
                if (prevIndex + 1 > data?.length - 1) {
                    return 0;
                }
                return prevIndex + 1;
            });
        };
        const interval = setInterval(handleClick, 3000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const redirectToNewURL = (name) => {
        if (name === "login") {
            window.open('https://app.brangchain.com.au/login', '_blank');
        } else {
            navigate("/register")
        }
    }

    return (
        <>
            <Container maxWidth="xl">
                <Box >
                    <Grid container spacing={2} sx={{
                        margin: 'auto',
                        alignItems: 'center',
                    }}>
                        <Grid item xl={4} lg={3} md={6} sm={6} xs={12} sx={{
                            paddingLeft: { xl: '16px !important', lg: '16px !important', md: '0px !important', sm: '0px !important', xs: '0px !important' },
                        }}>
                            <Box className="leading-text" sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                                color: '#000',
                                fontSize: { xl: '45px', lg: '40px', md: '30px', sm: '25px', xs: '25px' },
                                fontWeight: '500',
                                margin: '30px 0px 0px 0px',
                                justifyContent: { xl: 'center', lg: 'center', md: 'start', sm: 'start', xs: 'center' },
                                '& p': {
                                    margin: '0'
                                }
                            }}>
                                <p>{leadingText}</p>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={4} md={6} sm={6} xs={12} sx={{
                            paddingLeft: { xl: '16px !important', lg: '16px !important', md: '0px !important', sm: '0px !important', xs: '0px !important' },
                        }}>
                            <Box className="slides" sx={{
                                alignSelf: "flex-start",
                                flex: "1",
                                width: "100%",
                                overflow: "hidden",
                            }}>
                                <Box className="carousel-inner" sx={{
                                    position: "relative",
                                    maxHeight: "550px",
                                    height: "500px",
                                    textAlign: 'center',
                                    overflow: "hidden",
                                    '& div': {
                                        '&.active': {
                                            color: '#000000',
                                            fontSize: { xl: '50px', lg: '40px', md: '35px', sm: '30px', xs: '30px' },
                                            backgroundColor: 'transparent',
                                            lineHeight: '40px',
                                            fontWeight: '600',
                                            opacity: '1'
                                        }
                                    }
                                }}>
                                    {data?.map((item, i) => (
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                background: "none",
                                                border: "none",
                                                padding: "0",
                                                margin: "0",
                                                opacity: "0",
                                                top: "50%",
                                                cursor: 'pointer',
                                                transition: "transform 0.4s ease, opacity 0.4s ease",
                                                opacity: '0',
                                                fontSize: { xl: '30px', lg: '30px', md: '28px', sm: '28px', xs: '25px' },
                                                textTransform: 'capitalize',
                                                width: '100%',
                                                '&:hover': {
                                                    backgroundColor: 'transparent'
                                                },
                                                '&.visible': {
                                                    opacity: '0.5',
                                                },
                                                '& span': {
                                                    display: 'none'
                                                }
                                            }}

                                            onClick={() => setActiveIndex(i)}
                                            // className={`carousel-item ${activeIndex === i ? "active" : ""} ${Math.abs(determinePlacement(i)) <= visibleStyleThreshold ? "visible" : ""
                                            //     }`}
                                            className={("carousel-item", {
                                                active: activeIndex === i,
                                                visible: true
                                            })}
                                            key={item.id}
                                            style={{
                                                transform: `translateY(${determinePlacement(i)}px)`,
                                            }}
                                        >
                                            {item.introline}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xl={5} lg={5} md={12} sm={12} xs={12} sx={{
                            paddingLeft: { xl: '16px !important', lg: '16px !important', md: '0px !important', sm: '0px !important', xs: '0px !important' },
                        }}>
                            <Box>
                                <Box
                                    sx={{
                                        marginBottom: { xl: '35px', lg: '0px', md: '30px', sm: '25px', xs: '25px' },
                                        height: { xl: '490px', lg: '400px', md: '350px', sm: '350px', xs: '350px' },
                                        position: 'relative',
                                        '& img': {
                                            width: '100%',
                                            height: "100%",
                                            objectFit: 'cover',
                                            borderTopLeftRadius: '30px',
                                            borderBottomLeftRadius: '30px',
                                        },
                                        '&:before': {
                                            content: "''",
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: '#00000062',
                                            position: 'absolute',
                                            borderTopLeftRadius: '30px',
                                            borderBottomLeftRadius: '30px'
                                        }
                                    }}
                                >
                                    <img
                                        src={data[activeIndex]?.content.image}
                                        alt={data[activeIndex].content.introline}
                                    />
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 'auto',
                                        bottom: { xl: '40px', lg: '40px', md: '30px', sm: '30px', xs: '25px' },
                                        left: '20px'
                                    }}>
                                        <Typography sx={{
                                            color: '#FFFFFF',
                                            fontSize: { xl: '39px', lg: '35px', md: '30px', sm: '30px', xs: '22px' },
                                            fontWeight: '500',
                                            marginBottom: '0px',
                                        }}>Use BrangChain to deliver</Typography>
                                        <Typography sx={{
                                            color: '#FFFFFF',
                                            fontSize: { xl: '33px', lg: '30px', md: '30px', sm: '30px', xs: '22px' },
                                            fontWeight: '500',
                                            marginBottom: '10px',
                                        }}>your products same day</Typography>
                                        <Typography sx={{
                                            color: '#ffffff',
                                            fontWeight: '500',
                                            opacity: '0.8',
                                            '& br': {
                                                display: { xs: 'none', lg: 'none', md: 'block', sm: 'block' }
                                            }
                                        }}>Call us now to avail special discount!</Typography>
                                    </Box>
                                </Box>
                                <Typography sx={{
                                    fontSize: { xl: '39px', lg: '30px', md: '30px', sm: '30px', xs: '25px' },
                                    fontWeight: '500',
                                    marginBottom: '20px',
                                }}>{data[activeIndex]?.content.mainTitle}</Typography>
                                <Box sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '20px',
                                    paddingRight: { xl: '0px', lg: '0px', md: '0px', sm: '0px', xs: '16px' },
                                    position: 'relative',
                                    top: '0px',
                                    paddingBottom: '15px'
                                }}>
                                    <Button sx={{
                                        backgroundColor: '#000 !important',
                                        fontSize: '22px',
                                        color: '#FFFFFF',
                                        textTransform: 'capitalize',
                                        borderRadius: '60px',
                                        padding: '6px 85px',
                                        width: { xl: 'inherit', lg: 'inherit', md: 'inherit', sm: 'inherit', xs: '100%' },
                                        '& span': {
                                            display: 'none'
                                        }
                                    }}
                                        onClick={() => redirectToNewURL("login")}
                                    >Login </Button>
                                    <Button sx={{
                                        backgroundColor: '#000 !important',
                                        fontSize: '22px',
                                        color: '#FFFFFF',
                                        textTransform: 'capitalize',
                                        borderRadius: '60px',
                                        padding: '6px 80px',
                                        width: { xl: 'inherit', lg: 'inherit', md: 'inherit', sm: 'inherit', xs: '100%' },
                                        '& span': {
                                            display: 'none'
                                        }
                                    }}
                                        onClick={() => redirectToNewURL("register")}
                                    >Register </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box >
            </Container >
        </>
    );
};


export default VerticalSlider;
