// import { Box, Container, Grid, List, ListItem, Typography } from '@mui/material'
import React from 'react';
import { Box, Container, Grid, List, ListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import logo from "../../img/BrangChain-logo.png";
import whiteLogo from "../../img/brangchain_white_logo.png";
import playStoreImage from "../../img/google_play_icon.png";
import appleStoreImage from "../../img/app_store_icon.png";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
    const redirectToNewURL = (name) => {
        if (name === "playstore") {
            window.open('https://play.google.com/store/apps/details?id=com.brang', '_blank');
        } else {
            window.open('https://testflight.apple.com/join/JddbRrMz', '_blank');
        }
    };

    const location = useLocation();
    const registerRoute = location.pathname.includes('register');
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Box sx={{ background: '#fff', color: '#fff', borderTop: '1px solid rgb(128, 128, 126, 0.3)' }}>
            <Container maxWidth="xl">
                <Grid container spacing={2}
                    sx={{

                        justifyContent: 'space-between',
                        padding: { xl: '50px 0px', lg: '50px 0px', md: '25px 0px', sm: '0px', xs: '0px' },
                    }}

                >
                    <Grid sx={{
                        display: { xl: 'block', lg: 'block', md: 'block', sm: 'none', xs: 'none' }
                    }} item xs={12} sm={6} md={3} lg={3} >
                        <List >
                            <ListItem sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '15px',
                                "& img": {
                                    width: "100%",
                                    maxWidth: '200px',
                                }
                            }}>
                                <Box>
                                    {/* <img src={logo} /> */}
                                    <img src={isDesktop ? registerRoute ? whiteLogo : logo : logo} />
                                    {/* <Typography>Bran Img</Typography> */}
                                </Box>
                                <Typography variant='p' sx={{
                                    color: { xs: registerRoute ? '#fff' : '#333', lg: registerRoute ? '#fff' : '#333', md: '#333', sm: '#333', xs: '#333' },
                                    fontSize: '14px'
                                }}>‘Send’ or ‘Receive’ anything, anywhere and watch it get delivered in real-time as one of our brangers brings you all the things you love!</Typography>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                        <List>
                            <Box>
                                <Typography sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '15px',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '26px',
                                    marginBottom: '15px',
                                    textTransform: 'uppercase',
                                    position: 'relative',
                                    color: registerRoute ? '#fff' : '#000',
                                    color: { xs: registerRoute ? '#fff' : '#000', lg: registerRoute ? '#fff' : '#000', md: '#000', sm: '#000', xs: '#000' },

                                }}>Download Our App</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px',
                                "& img": {
                                    cursor: "pointer",
                                    width: { xl: '200px', lg: '100px', md: '100px', sm: '100px', xs: '100px' },
                                }
                            }}>
                                <img src={playStoreImage}
                                    onClick={() => redirectToNewURL("playstore")}
                                />
                                <img src={appleStoreImage} onClick={() => redirectToNewURL("app_store")} />
                            </Box>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                        <List sx={{
                            "& a": {
                                color: 'rgba(255, 255, 255, 0.7)',
                                fontSize: '14px',
                                margin: 0,
                                cursor: 'pointer',
                                fontWeight: 500,
                                color: '#333',
                                textDecoration: "none"
                            }
                        }}>
                            <Box>
                                <Typography

                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '15px',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        lineHeight: '26px',
                                        marginBottom: '15px',
                                        textTransform: 'uppercase',
                                        position: 'relative',
                                        color: '#000',
                                    }}
                                >QUICK LINKS</Typography>
                            </Box>
                            <ListItem sx={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                fontSize: '14px',
                                padding: '0 0 15px 0px',
                                margin: 0,
                                cursor: 'pointer',
                                fontWeight: 500,
                                color: '#333',

                            }} ><Link to="/">Home</Link></ListItem>
                            <ListItem sx={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                fontSize: '14px',
                                padding: '0 0 15px 0px',
                                margin: 0,
                                cursor: 'pointer',
                                fontWeight: 500,
                                color: '#333',
                            }}><Link to="/customers">Customers</Link></ListItem>
                            <ListItem sx={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                fontSize: '14px',
                                padding: '0 0 15px 0px',
                                margin: 0,
                                cursor: 'pointer',
                                fontWeight: 500,
                                color: '#333',
                            }}><Link to="/drivers">Drivers</Link></ListItem>
                            <ListItem sx={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                fontSize: '14px',
                                padding: '0 0 15px 0px',
                                margin: 0,
                                cursor: 'pointer',
                                fontWeight: 500,
                                color: '#333',
                            }}><Link to="/blogs">Blog</Link></ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                        <List>
                            <Box>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '15px',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        lineHeight: '26px',
                                        marginBottom: '15px',
                                        textTransform: 'uppercase',
                                        position: 'relative',
                                        color: '#000',
                                    }}
                                >OUR SUPPORT</Typography>
                            </Box>
                            <ListItem sx={{
                                padding: '0 0 15px 0px',
                                "& a": {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    fontSize: '14px',
                                    margin: 0,
                                    cursor: 'pointer',
                                    fontWeight: 500,
                                    color: '#333',
                                    textDecoration: "none"
                                }
                            }}><Link to="/privacy-policy">Privacy Policy</Link></ListItem>
                            <ListItem sx={{
                                padding: '0 0 15px 0px',
                                "& a": {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    fontSize: '14px',
                                    margin: 0,
                                    cursor: 'pointer',
                                    fontWeight: 500,
                                    color: '#333',
                                    textDecoration: "none"
                                }
                            }}><Link to="/terms-conditions">Terms & Conditions</Link></ListItem>

                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                        <List>
                            <Box>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '15px',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        lineHeight: '26px',
                                        marginBottom: '15px',
                                        textTransform: 'uppercase',
                                        position: 'relative',
                                        color: '#000',
                                    }}
                                >CONTACT US</Typography>
                            </Box>
                            <ListItem sx={{
                                padding: '0 0 15px 0px',
                                "& a": {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    fontSize: '14px',
                                    margin: 0,
                                    cursor: 'pointer',
                                    fontWeight: 500,
                                    color: '#333',
                                    textDecoration: "none"
                                }
                            }}><Link to="tel:1300027264">13000BRANG</Link></ListItem>
                            <ListItem sx={{
                                padding: '0 0 15px 0px',
                                "& a": {
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    fontSize: '14px',
                                    margin: 0,
                                    cursor: 'pointer',
                                    fontWeight: 500,
                                    color: '#333',
                                    textDecoration: "none"
                                }
                            }}><Link to="mailto:connect@brang.com.au">connect@brang.com.au</Link></ListItem>
                            <ListItem sx={{
                                paddingLeft: "0px"
                            }}><Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    cursor: 'pointer',
                                    fontWeight: 500,
                                }}>
                                    <Box sx={{
                                        '&:hover': {
                                            '& svg': {
                                                fill: "#0046f7"
                                            },
                                        },
                                        '& a': {
                                            display: 'flex',
                                            color: "#000"
                                        }
                                    }}><Link to="https://www.facebook.com/thinkbrang/" target="_blank" ><FacebookIcon color="#000" /></Link></Box>
                                    <Box sx={{
                                        '&:hover': {
                                            '& svg': {
                                                fill: "#0046f7"
                                            },
                                        },
                                        '& a': {
                                            display: 'flex',
                                            color: "#000"
                                        }
                                    }}><Link to="https://www.instagram.com/thinkbrang/" target="_blank" ><InstagramIcon color="#000" /></Link></Box>
                                    <Box sx={{
                                        '&:hover': {
                                            '& svg': {
                                                fill: "#0046f7"
                                            },
                                        },
                                        '& a': {
                                            display: 'flex',
                                            color: "#000"
                                        }
                                    }}><Link to="https://www.linkedin.com/company/brang-think-delivery-think-brang/" target="_blank" ><LinkedInIcon color="#000" /> </Link></Box>
                                </Box></ListItem>
                        </List>
                    </Grid>
                </Grid>

            </Container>

        </Box >
    )
}

export default Footer;
