import './App.css';
import { Navigate, Route, Routes } from "react-router-dom";
import Brangres from "./view/brangres/Brangres";
import Customers from "./view/customers/Customers";
// import Sustainability from "./view/sustainability/Sustainability";
import Register from "./view/register/Register";
import Home from './view/home/Home';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "./gilroy-font/stylesheet.css";
import Chapter from './view/home/Chapter';
import Blogs from './view/blogs/Blogs';
import BlogDetailsPage from "./view/blogs/BlogDetailsPage";
import PrivacyPolicy from './view/privacy-policy/PrivacyPolicy';
import TermsConditions from './view/terms-conditions/TermsConditions';
import News from './view/news/News';


const theme = createTheme({
  typography: {
    fontFamily: [
      'Gilroy',
      'Gilroy-Medium',
      // Add more font package names as needed
    ].join(','),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/drivers" element={<Brangres />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<BlogDetailsPage />} />
        <Route path="/news" element={<News />} />
        {/* <Route path="/Sustainability" element={<Sustainability />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/chapter" element={<Chapter />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;