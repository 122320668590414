import { Box, Button, Container, Grid, Link, List, ListItem, Typography } from "@mui/material"
import Layout from "../layout/Layout"
import homebanner from '../../img/home-branger.svg'
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MetaContainer from "../../seo/MetaContainer";


const Home = () => {
    const navigate = useNavigate();

    const handleChapterRedirect = (ativeTab) => {
        const state = { ativeTab: ativeTab }
        navigate("/chapter")
    };

    return (
        <Layout>
            <MetaContainer
                title='Brangchain-think delivery,think Brangchain'
                description='Brangchain is a delivery platform providing same day delivery service all across Australia currently in Melbourne. It is contact less, instant, reliable, insured , 24 / 7 delivery platform taking care of all your delivery needs.'
                keywords='Delivery,click and collect delivery,same day delivery,pharmacy delivery,instant delivery,aged care,delivery partner,stores delivery,essentials ,australia,melbourne,24x7,reliable,superfast,insured,contactless delivery, BrangChain, Brangchain, think delivery'
                imageURL='https://careers.inkitsolutions.com.au/media/images/image_2024_08_22T09_17_21_722Z.png'
                pageURL='https://brangchain.com.au/'
            />
            <Container maxWidth="xl" sx={{
                paddingRight: { xl: '0px !important', lg: '0px !important', md: 'inherit !important', sm: 'inherit !important', xs: 'inherit !important' }
            }}>
                <Box sx={{
                    paddingBottom: {lg: '40px', md: '0px', sm: '0px', xs: '0px'},
                }}>
                    <Grid container spacing={2} sx={{
                        // marginTop: '50px',
                        alignItems: 'center',
                        height: { xl: 'Calc(100vh - 99px)', lg: 'Calc(100vh - 99px)', md: 'auto', sm: 'auto', xs: 'auto' },

                    }}>
                        <Grid item xl={7} lg={7} md={12} sm={12} xs={12} sx={{
                            paddingRight: '16px',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            margin: '40px 0',

                        }}>
                            <Box sx={{
                                maxWidth: '650px',
                                width: '100%',
                                textAlign: { xl: 'start', lg: 'start', md: 'center', sm: 'center', xs: 'center' },
                                margin: { xl: 'inherit', lg: 'inherit', md: '0 auto', sm: '0 auto', xs: '0 auto' },
                            }}>
                                <Typography variant="h2" sx={{
                                    fontSize: { xl: '52px', lg: '35px', md: '30px', sm: '30px', xs: '25px' },
                                    lineHeight: { xl: '68px', lg: '42px', md: '40px', sm: '40px', xs: '30px' },
                                    fontWeight: '500',
                                    textTransform: 'capitalize',
                                    marginBottom: { xl: '30px', lg: '30px', md: '20px', sm: '20px', xs: '20px' },
                                }}>the world's first sustainable last-mile delivery solution powered by blockChain</Typography>
                                <Button
                                    onClick={handleChapterRedirect}
                                    sx={{
                                        backgroundColor: '#000 !important',
                                        fontSize: '22px',
                                        color: '#FFFFFF',
                                        textTransform: 'capitalize',
                                        borderRadius: '60px',
                                        padding: '6px 100px',
                                        '& span': {
                                            display: 'none'
                                        }
                                    }}>Explore </Button>
                            </Box>
                            <Grid container spacing={1} sx={{
                                marginTop: { xl: '30px', lg: '30px', md: '30px', sm: '30px', xs: '50px' },
                                alignItems: 'baseline',
                            }}>
                                <Grid item lg={3} md={6} sm={6} xs={6} >
                                    <Box sx={{
                                        cursor: 'pointer',
                                    }}
                                        onClick={() => handleChapterRedirect(0)}
                                    >
                                        {/* <Typography variant='h6' sx={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        opacity: '0.5',
                                        textTransform: 'uppercase',
                                        marginBottom: '7px'
                                    }}>Brang</Typography> */}
                                        <Typography variant="h3" sx={{
                                            color: '#000000',
                                            fontSize: { xl: '28px', lg: '25px', md: '25px', sm: '22px', xs: '20px' },
                                            fontWeight: '500',
                                            marginBottom: '7px'
                                        }}>Chapter 1</Typography>
                                        <Typography sx={{
                                            color: '#000000',
                                            fontSize: '16px',
                                            opacity: '0.5',
                                            fontWeight: '500',
                                            marginBottom: '7px'
                                        }}>What is BrangChain?</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={6} sm={6} xs={6}>
                                    <Box sx={{
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => handleChapterRedirect(1)}
                                    >
                                        {/* <Typography variant='h6' sx={{
                                        color: '#000000',
                                        fontSize: '14px',
                                        opacity: '0.5',
                                        textTransform: 'uppercase',
                                        marginBottom: '7px',
                                    }}>Brang</Typography> */}
                                        <Typography variant="h3" sx={{
                                            color: '#000000',
                                            fontSize: { xl: '28px', lg: '25px', md: '25px', sm: '22px', xs: '20px' },
                                            fontWeight: '500',
                                            marginBottom: '7px'
                                        }}>Chapter 2</Typography>
                                        <Typography sx={{
                                            color: '#000000',
                                            fontSize: '16px',
                                            opacity: '0.5',
                                            fontWeight: '500',
                                            marginBottom: '7px'
                                        }}>How does it work with merchants?</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={6} sm={6} xs={6}>
                                    <Box sx={{
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => handleChapterRedirect(2)}
                                    >
                                        {/* <Typography variant='h6' sx={{
                                        color: '#000000',
                                        opacity: '0.5',
                                        fontSize: '14px',
                                        textTransform: 'uppercase',
                                        marginBottom: '7px'
                                    }}>Brang</Typography> */}
                                        <Typography variant="h3" sx={{
                                            color: '#000000',
                                            fontSize: { xl: '28px', lg: '25px', md: '25px', sm: '22px', xs: '20px' },
                                            fontWeight: '500',
                                            marginBottom: '7px'
                                        }}>Chapter 3</Typography>
                                        <Typography sx={{
                                            color: '#000000',
                                            fontSize: '16px',
                                            opacity: '0.5',
                                            fontWeight: '500',
                                            marginBottom: '7px'
                                        }}>How does it work with drivers?</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={6} sm={6} xs={6}>
                                    <Box sx={{
                                        cursor: 'pointer'
                                    }}
                                        onClick={() => handleChapterRedirect(3)}
                                    >
                                        {/* <Typography variant='h6' sx={{
                                        color: '#000000',
                                        opacity: '0.5',
                                        fontSize: '14px',
                                        textTransform: 'uppercase',
                                        marginBottom: '7px'
                                    }}>Brang</Typography> */}
                                        <Typography variant="h3" sx={{
                                            color: '#000000',
                                            fontSize: { xl: '28px', lg: '25px', md: '25px', sm: '22px', xs: '20px' },
                                            fontWeight: '500',
                                            marginBottom: '7px'
                                        }}>Chapter 4</Typography>
                                        <Typography sx={{
                                            color: '#000000',
                                            fontSize: '16px',
                                            opacity: '0.5',
                                            fontWeight: '500',
                                            marginBottom: '7px'
                                        }}>How does it work with customers?</Typography>
                                    </Box>
                                </Grid>
                            </Grid>


                        </Grid>
                        <Grid item xl={5} lg={5} md={12} sm={12} xs={12} sx={{
                            display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                            height: '100%',
                            order: { xl: 'inherit', lg: 'inherit', md: 'inherit', sm: '-2', xs: '-2' },
                        }}>
                            <Box sx={{
                                width: { xl: '100%', lg: '100%', md: '100%', sm: '500px', xs: 'auto' },
                                height: { xl: '100%', lg: '100%', md: '100%', sm: '350px', xs: 'auto' },
                                objectFit: 'cover',
                                margin: { xl: 'auto', lg: 'auto', md: 'auto', sm: '0 auto', xs: '0 auto 20px auto' },
                                '& img': {
                                    width: '100%',
                                    height: '100%',
                                }
                            }}>
                                <img src={homebanner} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Layout>
    )
};

export default Home;