
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Input, InputAdornment, InputLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import Layout from "../layout/Layout";
import customer from '../../img/customer-img 1.jpg';
import { useState } from "react";
import leftarrow from '../../img/arrow-left.svg';
import rightarrow from '../../img/arrow-right.svg';
import deleiveries from '../../img/deliveries-img.jpg';
import coverstore from '../../img/store-img.jpg';
import deliveriesmen from '../../img/deliveries-men.jpg';
import provideservice from '../../img/provide-service.jpg';
import grocerydelivery from '../../img/grocery-delivery.svg';
import quote from '../../img/quote-img.svg';
import weekend from '../../img/weekend-bucks.svg';
import grocerymen from '../../img/delivery-man-making.svg';
import depositphoto from '../../img/deposit-photos.svg';
import mobilecontent from '../../img/mobile-content.jpg';
import { useNavigate } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import MetaContainer from "../../seo/MetaContainer";

const Register = () => {
    const [step, setStep] = useState(1);
    const validateWhiteSpace = /^\s*$/;
    const australiaPostalCodeRegex = /^\d{4}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[0-9\s\-\(\)]{7,}$/;


    const [data, setData] = useState({
        business_name: "",
        expected_weekly_deliveries: "",
        area_from_store_km: "",
        postal_code: "",
        start_date: "",
        email: "",
        phone: "",
    });

    const [error, setError] = useState({
        business_name: "",
        expected_weekly_deliveries: "",
        area_from_store_km: "",
        postal_code: "",
        start_date: "",
        email: "",
        phone: "",
    });

    const handleValidation = () => {
        const { business_name, expected_weekly_deliveries, area_from_store_km, postal_code, start_date, email, phone } = data;
        let tempError = false;
        if (step === 1) {
            if (!business_name) {
                setError((prev) => ({ ...prev, business_name: 'Please enter the bussiness name' }));
                tempError = true;
            }
            if (business_name && validateWhiteSpace.test(business_name)) {
                setError((prev) => ({ ...prev, business_name: 'Whitespace is not allowed' }));
                tempError = true;
              }
        }
        if (step === 2) {
            if (!expected_weekly_deliveries) {
                setError((prev) => ({ ...prev, expected_weekly_deliveries: 'Please select the deliveries' }));
                tempError = true;
            }
        }
        if (step === 3) {
            if (!area_from_store_km) {
                setError((prev) => ({ ...prev, area_from_store_km: 'Please select the delivery area' }));
                tempError = true;
            }
        }
        if (step === 4) {
            if (!postal_code) {
                setError((prev) => ({ ...prev, postal_code: 'Please enter the postal code' }));
                tempError = true;
            }
            if (postal_code && validateWhiteSpace.test(postal_code)) {
                setError((prev) => ({ ...prev, postal_code: 'Whitespace is not allowed' }));
                tempError = true;
              }
            if (postal_code && !australiaPostalCodeRegex.test(postal_code)) {
                setError((prev) => ({ ...prev, postal_code: 'Please enter a valid postal code' }));
                tempError = true;
              }

        }
        if (step === 5) {
            if (!start_date) {
                setError((prev) => ({ ...prev, start_date: 'Please select the start date' }));
                tempError = true;
            }
        }
        if (step === 6) {
            if (!email) {
                setError((prev) => ({ ...prev, email: 'Please enter the email' }));
                tempError = true;
            }
            
              if (email && !emailRegex.test(email)) {
                setError((prev) => ({ ...prev, email: 'Please enter a valid email address.' }));
                tempError = true;
              } 
        }
        if (step === 7) {
            if (!phone) {
                setError((prev) => ({ ...prev, phone: 'Please enter the phone' }));
                tempError = true;
            }
            if (phone && validateWhiteSpace.test(phone)) {
                setError((prev) => ({ ...prev, phone: 'Whitespace is not allowed' }));
                tempError = true;
              }
            if (phone && !phoneRegex.test(phone)) {
                setError((prev) => ({ ...prev, phone: 'Please enter a valid phone number.' }));
                tempError = true;
              }
        }

        if (!tempError && step < 7) {
            tempError = false;
            setStep(step + 1)
        }
        return tempError;
    };

    const StepOne = () => {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xl={6} lg={6}>
                        <Box sx={{
                            maxWidth: '46%',
                            width: '100%',
                            flex: '0 0 500px',
                            position: 'fixed',
                            top: '0',
                            bottom: '0',
                            display: { xs: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                zIndex: '999',
                                background: ' linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.50) 100%)'
                            },
                            '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                position: 'relative',
                                objectPosition: 'top',
                            },

                        }}>
                            <img src={customer} />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{
                        paddingLeft: { xl: '0px !important', lg: '0px !important' }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'baseline',
                            justifyContent: 'center',
                            height: 'calc(100vh - 120px)',
                            paddingLeft: { xl: 'inherit !important', lg: 'inherit !important', md: '24px !important', sm: '24px !important', xs: '18px !important' },
                            paddingRight: { xl: 'inherit !important', lg: 'inherit !important', md: '24px !important', sm: '32px !important', xs: '24px !important' },
                        }}>

                            <InputLabel htmlFor="input-with-icon-adornment" sx={{
                                color: '#000000',
                                fontSize: { xs: '41px', lg: '30px', md: '30px', sm: '25px', xs: '25px' },
                                textTransform: 'capitalize',
                                marginBottom: { xl: '39px', lg: '20px', md: "10px", sm: '10px', xs: '10px' },
                                fontWeight: '500'
                            }}>
                                name of the business
                            </InputLabel>
                            <FormControl variant="standard" sx={{
                                width: { xl: '85%', lg: '85%', md: '100%', sm: '100%', xs: '100%' },
                            }}>

                                <Input sx={{
                                    fontSize: { xl: '25px', lg: '22px', md: "18px", sm: '18px', xs: '20px' },
                                    fontWeight: '500',
                                    '& input': {
                                        marginBottom: { xl: '20px', lg: '10px', md: "10px", sm: '10px', xs: '10px' },
                                    },
                                    '& ::placeholder': {
                                        fontSize: { xl: '25px', lg: '22px', md: "18px", sm: '18px', xs: '20px' },
                                    }
                                }}
                                    value={data?.business_name}
                                    onChange={(event) => {
                                        setData((pre) => ({ ...pre, business_name: event.target.value }));
                                        setError((prev) => ({ ...prev, business_name: '' }));
                                    }}
                                    placeholder="BrangChain"
                                    id="input-with-icon-adornment"
                                    endAdornment={
                                        <Box sx={{
                                            width: '50px',
                                            height: '50px',
                                            border: ' 2px solid rgba(38, 177, 255, 0.28);',
                                            borderRadius: '100px',
                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            marginBottom: { xl: '20px', lg: '20px', md: "15px", sm: '15px', xs: '15px' },
                                            flex: '0 0 50px'
                                        }}

                                            onClick={() => handleValidation()}>
                                            <img src={rightarrow} />
                                        </Box>
                                    }
                                />
                            </FormControl>
                            {error?.business_name && <Typography sx={{
                                marginTop: '10px',
                                color: '#f00'
                            }}>{error?.business_name ? error.business_name : ''}</Typography>}
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        )
    };

    const StepTwo = () => {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xl={6} lg={6}>
                        <Box sx={{
                            maxWidth: '46%',
                            width: '100%',
                            position: 'fixed',
                            top: '0',
                            bottom: '0',
                            display: { xs: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                zIndex: '999',
                                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%)'
                            },
                            '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                position: 'relative',
                                objectPosition: 'top',
                            },

                        }}>
                            <img src={deleiveries} />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{
                        paddingLeft: { xl: '0px !important', lg: '0px !important' }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'baseline',
                            justifyContent: 'center',
                            // height: { xl: 'calc(100vh - 100px)', lg: 'calc(100vh - 100px)', md: '100vh', sm: '100vh', xs: '100vh' },
                            height: 'calc(100vh - 120px)',
                            paddingRight: '20px',
                            paddingLeft: { xs: 'inherit !important', lg: 'inherit !important', md: '24px !important', sm: '24px !important', xs: '17px !important' },
                        }}>

                            <Box sx={{
                                width: '50px',
                                height: '50px',
                                border: ' 2px solid #00000047;',
                                borderRadius: '100px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                marginBottom: { xl: '50px', lg: '10px', md: '30px', sm: '25px', xs: '25px' },
                                flex: '0 0 50px'
                            }}
                                onClick={() => setStep(step - 1)}>
                                <img src={leftarrow} />
                            </Box>

                            <FormControl>
                                <FormLabel id="" sx={{
                                    color: '#000000 !important',
                                    fontSize: { xs: '41px', lg: '25px', md: '30px', sm: '25px', xs: '25px' },
                                    textTransform: 'capitalize',
                                    marginBottom: { xl: '39px', lg: '15px', md: "30px", sm: '20px', xs: '25px' },
                                    fontWeight: '500',
                                    '& br': {
                                        display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' }
                                    }
                                }}> How many deliveries you do in<br />
                                    a week ?</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value="1-20" control={<Radio
                                        checked={data?.expected_weekly_deliveries === '1-20'}
                                        onChange={(e) => {
                                            setData(prev => ({ ...prev, expected_weekly_deliveries: e.target.value }))
                                            setError(prev => ({ ...prev, expected_weekly_deliveries: '' }));
                                        }}
                                    />} label="1 - 20 deliveries" sx={{
                                        border: '1px solid #00000050',
                                        borderRadius: '30px',
                                        maxWidth: '220px',
                                        marginBottom: '15px',
                                        width: '100%',
                                        marginLeft: '0px',
                                        '& span': {
                                            '& svg': {
                                                fill: data?.expected_weekly_deliveries === '1-20' ? '#2196f3' : '#00000050 !important'
                                            }
                                        },
                                        '& span:last-child': {
                                            fontSize: '18px',
                                            color: "#000",
                                            fontWeight: '500',
                                        },
                                    }} />
                                    <FormControlLabel value="20-40" control={<Radio
                                        checked={data?.expected_weekly_deliveries === '20-40'}
                                        onChange={(e) => {
                                            setData(prev => ({ ...prev, expected_weekly_deliveries: e.target.value }))
                                            setError(prev => ({ ...prev, expected_weekly_deliveries: '' }));
                                        }}
                                    />} label="20 - 40 deliveries" sx={{
                                        border: '1px solid #00000050',
                                        borderRadius: '30px',
                                        maxWidth: '220px',
                                        marginBottom: '15px',
                                        width: '100%',
                                        marginLeft: '0px',
                                        '& span': {
                                            '& svg': {
                                                fill: data?.expected_weekly_deliveries === '20-40' ? '#2196f3' : '#00000050 !important'
                                            }
                                        },
                                        '& span:last-child': {
                                            fontSize: '18px',
                                            color: "#000",
                                            fontWeight: '500',
                                        },
                                    }} />
                                    <FormControlLabel value="40-60" control={<Radio
                                        checked={data?.expected_weekly_deliveries === '40-60'}
                                        onChange={(e) => {
                                            setData(prev => ({ ...prev, expected_weekly_deliveries: e.target.value }))
                                            setError(prev => ({ ...prev, expected_weekly_deliveries: '' }));
                                        }}
                                    />} label="40 - 60 deliveries" sx={{
                                        border: '1px solid #00000050',
                                        borderRadius: '30px',
                                        maxWidth: '220px',
                                        marginBottom: '15px',
                                        width: '100%',
                                        marginLeft: '0px',
                                        '& span': {
                                            '& svg': {
                                                fill: data?.expected_weekly_deliveries === '40-60' ? '#2196f3' : '#00000050 !important'
                                            }
                                        },
                                        '& span:last-child': {
                                            fontSize: '18px',
                                            color: "#000",
                                            fontWeight: '500',
                                        },
                                    }} />
                                    <FormControlLabel value="60-200" control={<Radio
                                        checked={data?.expected_weekly_deliveries === '60-200'}
                                        onChange={(e) => {
                                            setData(prev => ({ ...prev, expected_weekly_deliveries: e.target.value }))
                                            setError(prev => ({ ...prev, expected_weekly_deliveries: '' }));
                                        }} />} label="60  - 200 deliveries" sx={{
                                            border: '1px solid #00000050',
                                            borderRadius: '30px',
                                            maxWidth: '220px',
                                            marginBottom: { xl: '15px', lg: '15px', md: '0px', sm: '0px', xs: '0px' },
                                            marginLeft: '0px',
                                            width: '100%',
                                            '& span': {
                                                '& svg': {
                                                    fill: data?.expected_weekly_deliveries === '60-200' ? '#2196f3' : '#00000050 !important'
                                                }
                                            },
                                            '& span:last-child': {
                                                fontSize: '18px',
                                                color: "#000",
                                                fontWeight: '500',
                                            },
                                        }} />
                                </RadioGroup>
                            </FormControl>
                            {error?.expected_weekly_deliveries && <Typography sx={{
                                marginTop: '10px',
                                color: '#f00'
                            }}>{error?.expected_weekly_deliveries}</Typography>}
                            <Box sx={{
                                width: '50px',
                                height: '50px',
                                border: ' 2px solid #00000047;',
                                borderRadius: '100px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                marginBottom: '20px',
                                flex: '0 0 50px',
                                marginTop: { xl: '50px', lg: '10px', md: '30px', sm: '25px', xs: '25px' },
                            }}
                                onClick={handleValidation}>
                                <img src={rightarrow} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        )
    };

    const StepThree = () => {
        return (
            <Box>
                <Grid container spacing={2} >
                    <Grid item xl={6} lg={6}>
                        <Box sx={{
                            maxWidth: '46%',
                            width: '100%',
                            position: 'fixed',
                            top: '0',
                            bottom: '0',
                            display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                zIndex: '999',
                                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)'
                            },
                            '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                position: 'relative',
                                objectPosition: 'top',
                            },

                        }}>
                            <img src={coverstore} />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{
                        paddingLeft: { xl: '0px !important', lg: '0px !important' }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'baseline',
                            justifyContent: 'center',
                            // height: { xl: 'calc(100vh - 100px)', lg: 'calc(100vh - 100px)', md: '100vh', sm: '100vh', xs: '100vh' },
                            height: 'calc(100vh - 120px)',
                            paddingLeft: { xs: 'inherit !important', lg: 'inherit !important', md: '24px !important', sm: '24px !important', xs: '17px !important' },
                        }}>

                            <Box sx={{
                                width: '50px',
                                height: '50px',
                                border: ' 2px solid #00000047;',
                                borderRadius: '100px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                marginBottom: { xl: '50px', lg: '10px', md: "30px", sm: '30px', xs: '20px' },
                                flex: '0 0 50px'
                            }}
                                onClick={() => setStep(step - 1)}>
                                <img src={leftarrow} />
                            </Box>

                            <FormControl>
                                <FormLabel id="" sx={{
                                    color: '#000000 !important',
                                    fontSize: { xs: '41px', lg: '25px', md: '30px', sm: '25px', xs: '25px' },
                                    textTransform: 'capitalize',
                                    marginBottom: { xl: '39px', lg: '15px', md: "30px", sm: '30px', xs: '28px' },
                                    fontWeight: '500',
                                    paddingRight: '17px',
                                    '& br': {
                                        display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' }
                                    }

                                }}> How much area you want cover from <br />your store ?</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value="1-20" control={<Radio
                                        checked={data?.area_from_store_km === '1-20'}
                                        onChange={(e) => {
                                            setData({ ...data, area_from_store_km: e.target.value });
                                            setError({ ...error, area_from_store_km: '' })
                                        }}
                                    />} label="1 - 20 Kms" sx={{
                                        border: '1px solid #00000050',
                                        borderRadius: '30px',
                                        maxWidth: '220px',
                                        marginBottom: '15px',
                                        width: '100%',
                                        marginLeft: '0px',
                                        '& span': {
                                            '& svg': {
                                                fill: data?.area_from_store_km === '1-20' ? '#2196f3' : '#00000050 !important'
                                            }
                                        },
                                        '& span:last-child': {
                                            fontSize: '18px',
                                            color: "#000",
                                            fontWeight: '500',
                                        },
                                    }} />
                                    <FormControlLabel value="20-40" control={<Radio
                                        checked={data?.area_from_store_km === '20-40'}
                                        onChange={(e) => setData({ ...data, area_from_store_km: e.target.value })}
                                    />} label="20 - 40 Kms" sx={{
                                        border: '1px solid #00000050',
                                        borderRadius: '30px',
                                        maxWidth: '220px',
                                        marginBottom: '15px',
                                        width: '100%',
                                        marginLeft: '0px',
                                        '& span': {
                                            '& svg': {
                                                fill: data?.area_from_store_km === '20-40' ? '#2196f3' : '#00000050 !important'
                                            }
                                        },
                                        '& span:last-child': {
                                            fontSize: '18px',
                                            color: "#000",
                                            fontWeight: '500',
                                        },
                                    }} />
                                    <FormControlLabel value="40-60" control={<Radio
                                        checked={data?.area_from_store_km === '40-60'}
                                        onChange={(e) => {
                                            setData({ ...data, area_from_store_km: e.target.value })
                                            setError({ ...error, area_from_store_km: '' })
                                        }}
                                    />} label="40 - 60 Kms" sx={{
                                        border: '1px solid #00000050',
                                        borderRadius: '30px',
                                        maxWidth: '220px',
                                        marginBottom: '15px',
                                        width: '100%',
                                        marginLeft: '0px',
                                        '& span': {
                                            '& svg': {
                                                fill: data?.area_from_store_km === '40-60' ? '#2196f3' : '#00000050 !important'
                                            }
                                        },
                                        '& span:last-child': {
                                            fontSize: '18px',
                                            color: "#000",
                                            fontWeight: '500',
                                        },
                                    }} />
                                    <FormControlLabel value="60-200" control={<Radio
                                        checked={data?.area_from_store_km === '60-200'}
                                        onChange={(e) => {
                                            setData({ ...data, area_from_store_km: e.target.value });
                                            setError({ ...error, area_from_store_km: '' })
                                        }}
                                    />} label="60  - 200 Kms" sx={{
                                        border: '1px solid #00000050',
                                        borderRadius: '30px',
                                        maxWidth: '220px',
                                        marginBottom: { xl: '15px', lg: '15px', md: '0px', sm: '0px', xs: '0px' },
                                        width: '100%',
                                        marginLeft: '0px',
                                        '& span': {
                                            '& svg': {
                                                fill: data?.area_from_store_km === '60-200' ? '#2196f3' : '#00000050 !important'
                                            }
                                        },
                                        '& span:last-child': {
                                            fontSize: '18px',
                                            color: "#000",
                                            fontWeight: '500',
                                        },
                                    }} />
                                </RadioGroup>
                            </FormControl>
                            {error?.area_from_store_km && <Typography sx={{
                                marginTop: '10px',
                                color: '#f00'
                            }}>{error?.area_from_store_km}</Typography>}
                            <Box sx={{
                                width: '50px',
                                height: '50px',
                                border: ' 2px solid #00000047;',
                                borderRadius: '100px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                marginBottom: '20px',
                                flex: '0 0 50px',
                                marginTop: { xl: '50px', lg: '10px', md: "30px", sm: '30px', xs: '20px' },
                            }}
                                onClick={handleValidation}>
                                <img src={rightarrow} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        );
    };

    const StepFour = () => {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={6}>
                        <Box sx={{
                            maxWidth: '46%',
                            width: '100%',
                            position: 'fixed',
                            top: '0',
                            bottom: '0',
                            display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                zIndex: '999',
                                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)'
                            },
                            '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                position: 'relative',
                                objectPosition: 'top',
                            },

                        }}>
                            <img src={provideservice} />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{
                        paddingLeft: { xl: '0px !important', lg: '0px !important' }
                    }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'baseline',
                            justifyContent: 'center',
                            // height: { xl: 'calc(100vh - 100px)', lg: 'calc(100vh - 100px)', md: '100vh', sm: '100vh', xs: '100vh' },
                            height: 'calc(100vh - 120px)',
                            paddingLeft: { xs: 'inherit !important', lg: 'inherit !important', md: '24px !important', sm: '24px !important', xs: '18px !important' },
                            paddingRight: { xs: 'inherit !important', lg: 'inherit !important', md: '35px !important', sm: '32px !important', xs: '24px !important' },
                        }}>
                            <Box sx={{
                                width: '50px',
                                height: '50px',
                                border: ' 2px solid #00000047;',
                                borderRadius: '100px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                marginBottom: { xl: '50px', lg: '20px', md: "30px", sm: '30px', xs: '28px' },
                                flex: '0 0 50px'
                            }}
                                onClick={() => setStep(step - 1)}>
                                <img src={leftarrow} />
                            </Box>


                            <InputLabel htmlFor="input-with-icon-adornment" sx={{
                                color: '#000000',
                                fontSize: { xs: '41px', lg: '30px', md: '30px', sm: '25px', xs: '25px' },
                                textTransform: 'capitalize',
                                marginBottom: { xl: '20px', lg: '20px', md: "10px", sm: '10px', xs: '10px' },
                                fontWeight: '500',
                                whiteSpace: 'normal',
                            }}>
                                Provide your area postal code
                            </InputLabel>
                            <FormControl variant="standard" sx={{
                                width: { xl: '85%', lg: '85%', md: '100%', sm: '100%', xs: '100%' },
                            }}>

                                <Input
                                    value={data?.postal_code}
                                    onChange={(e) => {
                                        setData({ ...data, postal_code: e.target.value });
                                        setError({ ...error, postal_code: '' });
                                    }}
                                    sx={{
                                        fontSize: { xl: '25px', lg: '22px', md: "18px", sm: '18px', xs: '20px' },
                                        fontWeight: '500',
                                        '& input': {
                                            marginBottom: { xl: '20px', lg: '20px', md: "10px", sm: '10px', xs: '10px' },
                                        },
                                        '& ::placeholder': {
                                            fontSize: { xl: '25px', lg: '22px', md: "18px", sm: '18px', xs: '20px' },
                                        }
                                    }}

                                    placeholder="3001"
                                    id="input-with-icon-adornment"
                                    endAdornment={
                                        <Box sx={{
                                            width: '50px',
                                            height: '50px',
                                            border: ' 2px solid #00000047;',
                                            borderRadius: '100px',
                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            marginBottom: { xl: '20px', lg: '20px', md: "15px", sm: '15px', xs: '15px' },
                                            flex: '0 0 50px'
                                        }}
                                            onClick={handleValidation}>
                                            <img src={rightarrow} />
                                        </Box>
                                    }
                                />
                            </FormControl>
                            {error.postal_code && <Typography sx={{
                                marginTop: '10px',
                                color: '#f00'
                            }}>{error.postal_code}</Typography>}
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        )
    };
    
    const StepFive = () => {
        const formatDate = (originalDate) => {
            const date = new Date(originalDate);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`
        }

        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={6}>
                        <Box sx={{
                            maxWidth: '46%',
                            width: '100%',
                            position: 'fixed',
                            top: '0',
                            bottom: '0',
                            display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                zIndex: '999',
                                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)'
                            },
                            '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                position: 'relative',
                                objectPosition: 'top',
                            },

                        }}>
                            <img src={deliveriesmen} />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{
                        paddingLeft: { xl: '0px !important', lg: '0px !important' }
                    }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'baseline',
                            justifyContent: 'center',
                            // height: { xl: 'calc(100vh - 100px)', lg: 'calc(100vh - 100px)', md: '100vh', sm: '100vh', xs: '100vh' },
                            height: 'calc(100vh - 120px)',
                            paddingLeft: { xl: 'inherit !important', lg: 'inherit !important', md: '24px !important', sm: '24px !important', xs: '18px !important' },
                            paddingRight: { xl: 'inherit !important', lg: 'inherit !important', md: '24px !important', sm: '32px !important', xs: '24px !important' },
                        }}>
                            <Box sx={{
                                width: '50px',
                                height: '50px',
                                border: ' 2px solid #00000047;',
                                borderRadius: '100px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                marginBottom: { xl: '60px', lg: '20px', md: "30px", sm: '30px', xs: '20px' },
                                flex: '0 0 50px'
                            }}
                                onClick={() => setStep(step - 1)}>
                                <img src={leftarrow} />
                            </Box>

                            <InputLabel htmlFor="input-with-icon-adornment" sx={{
                                color: '#000000',
                                fontSize: { xs: '41px', lg: '30px', md: '30px', sm: '25px', xs: '25px' },
                                textTransform: 'capitalize',
                                marginBottom: { xl: '39px', lg: '15px', md: "30px", sm: '30px', xs: '28px' },
                                fontWeight: '500',
                                overflow: 'unset'
                            }}>
                                When do you want to start
                            </InputLabel>
                            <Box>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker sx={{
                                        maxWidth: '1000px',
                                        width: "100%",
                                        zIndex: '0 !important',
                                    }}
                                        label="Select Date"
                                        // value={data?.start_date || null}
                                        value={data?.start_date ? dayjs(data.start_date) : null}
                                        format="DD/MM/YYYY"
                                        onChange={(date) => {
                                            setData(prev => ({ ...prev, start_date: formatDate(date) }))
                                            setError(prev => ({ ...prev, start_date: "" }))
                                        }
                                        }
                                        PopperProps={{
                                            placement: 'bottom-start',
                                        }}
                                        renderInput={(params) => <TextField {...params} />
                                        }
                                    />
                                </LocalizationProvider>
                            </Box>
                            {error?.start_date && <Typography sx={{
                                marginTop: '10px',
                                color: '#f00'
                            }}>{error?.start_date}</Typography>}
                            <Box sx={{
                                width: '50px',
                                height: '50px',
                                border: ' 2px solid #00000047;',
                                borderRadius: '100px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                marginTop: { xl: '50px', lg: '20px', md: "30px", sm: '30px', xs: '28px' },
                                flex: '0 0 50px',
                            }}
                                onClick={handleValidation}>
                                <img src={rightarrow} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        )
    };

    const StepSix = () => {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={6}>
                        <Box sx={{
                            maxWidth: '46%',
                            width: '100%',
                            position: 'fixed',
                            top: '0',
                            bottom: '0',
                            display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                zIndex: '999',
                                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)'
                            },
                            '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                position: 'relative',
                                objectPosition: 'top',
                            },

                        }}>
                            <img src={provideservice} />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{
                        paddingLeft: { xl: '0px !important', lg: '0px !important' }
                    }}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'baseline',
                            justifyContent: 'center',
                            // height: { xl: 'calc(100vh - 100px)', lg: 'calc(100vh - 100px)', md: '100vh', sm: '100vh', xs: '100vh' },
                            height: 'calc(100vh - 120px)',
                            paddingLeft: { xs: 'inherit !important', lg: 'inherit !important', md: '24px !important', sm: '24px !important', xs: '18px !important' },
                            paddingRight: { xs: 'inherit !important', lg: 'inherit !important', md: '35px !important', sm: '32px !important', xs: '24px !important' },
                        }}>
                            <Box sx={{
                                width: '50px',
                                height: '50px',
                                border: ' 2px solid #00000047;',
                                borderRadius: '100px',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                marginBottom: { xl: '50px', lg: '20px', md: "30px", sm: '30px', xs: '28px' },
                                flex: '0 0 50px'
                            }}
                                onClick={() => setStep(step - 1)}>
                                <img src={leftarrow} />
                            </Box>


                            <InputLabel htmlFor="input-with-icon-adornment" sx={{
                                color: '#000000',
                                fontSize: { xs: '41px', lg: '30px', md: '30px', sm: '25px', xs: '25px' },
                                textTransform: 'capitalize',
                                marginBottom: { xl: '20px', lg: '20px', md: "10px", sm: '10px', xs: '10px' },
                                fontWeight: '500',
                                whiteSpace: 'normal',
                            }}>
                                Provide your email id to get a quote
                            </InputLabel>
                            <FormControl variant="standard" sx={{
                                width: { xl: '85%', lg: '85%', md: '100%', sm: '100%', xs: '100%' },
                            }}>

                                <Input
                                    value={data?.email}
                                    onChange={(e) => {
                                        setData({ ...data, email: e.target.value });
                                        setError({ ...error, email: "" })
                                    }}
                                    sx={{
                                        fontSize: { xl: '25px', lg: '22px', md: "18px", sm: '18px', xs: '20px' },
                                        fontWeight: '500',
                                        '& input': {
                                            marginBottom: { xl: '20px', lg: '20px', md: "10px", sm: '10px', xs: '10px' },
                                        },
                                        '& ::placeholder': {
                                            fontSize: { xl: '25px', lg: '22px', md: "18px", sm: '18px', xs: '20px' },
                                        }
                                    }}

                                    placeholder="messi@Goat.com"
                                    id="input-with-icon-adornment"
                                    endAdornment={
                                        <Box sx={{
                                            width: '50px',
                                            height: '50px',
                                            border: ' 2px solid #00000047;',
                                            borderRadius: '100px',
                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            marginBottom: { xl: '20px', lg: '20px', md: "15px", sm: '15px', xs: '15px' },
                                            flex: '0 0 50px'
                                        }}
                                            onClick={handleValidation}>
                                            <img src={rightarrow} />
                                        </Box>
                                    }
                                />
                            </FormControl>
                            {error?.email && <Typography sx={{
                                marginTop: '10px',
                                color: '#f00'
                            }}>{error?.email}</Typography>}
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        )
    };

    const [isSuccess, setIsSuccess] = useState(false);

    const addRegisteration = async () => {
        setIsSuccess(true);
        const baseURL = process.env.REACT_APP_API_BASE_URL

        try {
            const response = await fetch(`${baseURL}/operations/landing_registration/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();
            setIsSuccess(true);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const StepSeven = () => {
        const handleSubmit = async () => {
            const tempError = await handleValidation();
            if (!tempError) {
                addRegisteration();
            }
        };
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={6}>
                        <Box sx={{
                            maxWidth: '46%',
                            width: '100%',
                            position: 'fixed',
                            top: '0',
                            bottom: '0',
                            display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                zIndex: '999',
                                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)'
                            },
                            '& img': {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                position: 'relative',
                                objectPosition: 'top',
                            },

                        }}>
                            <img src={mobilecontent} />
                        </Box>
                    </Grid>
                    {
                        isSuccess ? <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{
                            paddingLeft: { xl: '0px !important', lg: '0px !important' }
                        }}> <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 'calc(100vh - 120px)',
                            fontWeight: 'bold',
                            color: '#000',
                            fontSize: '22px',
                            paddingLeft: { xs: 'inherit !important', lg: 'inherit !important', md: '24px !important', sm: '24px !important', xs: '18px !important' },
                            paddingRight: { xs: 'inherit !important', lg: 'inherit !important', md: '35px !important', sm: '32px !important', xs: '24px !important' },
                        }}>Hold tight, our exceptional team will call you in shortly!!</Box> </Grid> : <Grid item xl={6} lg={6} md={12} sm={12} xs={12} sx={{
                            paddingLeft: { xl: '0px !important', lg: '0px !important' }
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'baseline',
                                justifyContent: 'center',
                                height: 'calc(100vh - 120px)',
                                paddingLeft: { xs: 'inherit !important', lg: 'inherit !important', md: '24px !important', sm: '24px !important', xs: '18px !important' },
                                paddingRight: { xs: 'inherit !important', lg: 'inherit !important', md: '35px !important', sm: '32px !important', xs: '24px !important' },
                            }}>
                                <Box sx={{
                                    width: '50px',
                                    height: '50px',
                                    border: ' 2px solid #00000047;',
                                    borderRadius: '100px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    marginBottom: '20px',
                                    flex: '0 0 50px'
                                }}
                                    onClick={() => setStep(step - 1)}>
                                    <img src={leftarrow} />
                                </Box>

                                <InputLabel htmlFor="input-with-icon-adornment" sx={{
                                    color: '#000000',
                                    fontSize: { xs: '41px', lg: '30px', md: '30px', sm: '25px', xs: '25px' },
                                    textTransform: 'capitalize',
                                    marginBottom: { xl: '20px', lg: '20px', md: "10px", sm: '10px', xs: '10px' },
                                    fontWeight: '500',
                                    whiteSpace: 'normal',
                                }}>
                                    Provide your contact number
                                </InputLabel>
                                <FormControl variant="standard" sx={{
                                    width: { xl: '85%', lg: '85%', md: '100%', sm: '100%', xs: '100%' },
                                }}>

                                    <Input
                                        value={data?.phone}
                                        onChange={(e) => {
                                            setData({ ...data, phone: e.target.value });
                                            setError({ ...error, phone: '' });
                                        }}
                                        sx={{
                                            fontSize: { xl: '25px', lg: '22px', md: "18px", sm: '18px', xs: '20px' },
                                            fontWeight: '500',
                                            '& input': {
                                                marginBottom: { xl: '20px', lg: '10px', md: "10px", sm: '10px', xs: '10px' },
                                            },
                                            '& ::placeholder': {
                                                fontSize: { xl: '25px', lg: '22px', md: "18px", sm: '18px', xs: '20px' },
                                            }
                                        }}

                                        placeholder="+61 412345678"
                                        id="input-with-icon-adornment"
                                    />
                                </FormControl>
                                {error?.phone && <Typography sx={{
                                    marginTop: '10px',
                                    color: '#f00'
                                }}>{error?.phone}</Typography>}

                                <Box sx={{
                                    textAlign: 'right',
                                    width: { xl: '85%', lg: '85%', md: '100%', sm: '100%', xs: '100%' },
                                }}>
                                    <Button
                                        onClick={handleSubmit}
                                        sx={{
                                            backgroundColor: '#000 !important',
                                            fontSize: '22px',
                                            color: '#FFFFFF',
                                            textTransform: 'capitalize',
                                            borderRadius: '60px',
                                            padding: '4px 50px',
                                            marginTop: '20px',
                                            '& span': {
                                                display: 'none'
                                            }
                                        }}>Submit </Button>
                                </Box>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box >
        )
    };

    return (
        <Layout>
            <MetaContainer
                title='Register - Brangchain-think delivery,think Brangchain'
                description='Brangchain is a delivery platform providing same day delivery service all across Australia currently in Melbourne. It is contact less, instant, reliable, insured , 24 / 7 delivery platform taking care of all your delivery needs.'
                keywords='Delivery,click and collect delivery,same day delivery,pharmacy delivery,instant delivery,aged care,delivery partner,stores delivery,essentials ,australia,melbourne,24x7,reliable,superfast,insured,contactless delivery, BrangChain, Brangchain, think delivery'
                imageURL='https://careers.inkitsolutions.com.au/media/images/image_2024_08_22T09_17_21_722Z.png'
                pageURL='https://brangchain.com.au/register'
            />
            {step === 1 && StepOne()}
            {step === 2 && StepTwo()}
            {step === 3 && StepThree()}
            {step === 4 && StepFour()}
            {step === 5 && StepFive()}
            {step === 6 && StepSix()}
            {step === 7 && StepSeven()}
        </Layout>
    )
};

export default Register;