import React from 'react'
import Layout from '../layout/Layout'
import { Box, Button, Container, Grid, Link, List, ListItem, Typography } from '@mui/material'
import blogImg from '../../img/blog-bg.svg'
import BlogDetailsPage from './BlogDetailsPage'
import BlogList from './BlogList'
import BlogCategories from './BlogCategories';
import { blogList } from "./blogData"
import MetaContainer from '../../seo/MetaContainer'

const Blogs = () => {
    return (
        <Layout>
            <MetaContainer
                title='Blogs - Brangchain-think delivery,think Brangchain'
                description='Brangchain is a delivery platform providing same day delivery service all across Australia currently in Melbourne. It is contact less, instant, reliable, insured , 24 / 7 delivery platform taking care of all your delivery needs.'
                keywords='Delivery,click and collect delivery,same day delivery,pharmacy delivery,instant delivery,aged care,delivery partner,stores delivery,essentials ,australia,melbourne,24x7,reliable,superfast,insured,contactless delivery, BrangChain, Brangchain, think delivery'
                imageURL='https://careers.inkitsolutions.com.au/media/images/image_2024_08_22T09_17_21_722Z.png'
                pageURL='https://brangchain.com.au/blogs'
            />
            <Container maxWidth="xxl">
                <Box sx={{
                    marginTop: { xl: '50px', lg: '50px', md: '50px', sm: '40px', xs: '30px' }
                }}>
                    {/* <Typography sx={{
                        color: '#000',
                        opacity: '0.5',
                        maxWidth: { xl: '800px', lg: '800px', md: '800px', sm: '100%', xs: '100%' },
                        width: '100%',
                        margin: "0 auto"
                    }}>We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our compan We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our compan</Typography>
                    <Box sx={{
                        width: '-webkit-fill-available',
                        height: '100%',
                        backgroundImage: `url(${blogImg})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        padding: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' },
                        borderRadius: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' },
                        marginTop: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' },
                        height: '400px',
                        position: 'relative',
                        marginBottom: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' }
                    }}>
                        <Box sx={{
                            maxWidth: { xl: '500px', lg: '500px', md: '500px', sm: '500px', xs: '90%' },
                            width: '100%',
                            position: 'absolute',
                            bottom: '30px',
                            // wordBreak: 'break-all'
                        }}>
                            <Typography sx={{
                                fontSize: { xl: '40px', lg: '30px', md: '30px', sm: '30px', xs: '25px' },
                                color: '#fff',
                                textTransform: 'capitalize',
                                marginBottom: '15px',
                            }}>Use Brangchain To deliver your products same day</Typography>
                            <Typography sx={{
                                color: '#fff',
                                opacity: '0.5'
                            }}>We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our compan</Typography>
                        </Box>
                    </Box> */}
                </Box>
            </Container>
            <Container maxWidth="xxl" sx={{
                maxWidth: { xl: '1400px', }
            }}>
                <Grid container spacing={3}>
                    <Grid item xl={9} lg={9} md={9} sm={9} xs={12} >
                        <BlogList blogList={blogList} />
                    </Grid>
                    {/* <Grid item xl={9} lg={9} md={9} sm={9} xs={12} >
                        <BlogDetailsPage />
                    </Grid> */}
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12} >
                        <BlogCategories />
                    </Grid>
                </Grid>
            </Container>
        </Layout >
    )
}

export default Blogs;
