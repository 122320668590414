import React, { useState } from 'react';
import { AppBar, Box, Container, Drawer, IconButton, List, ListItem, ListItemText, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled, css } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from "react-router-dom";
import navbarbg from "../../img/navbar-bg.png"
import CloseIcon from '@mui/icons-material/Close';
import HeaderLogon from "../../img/BrangChain-logo.png";

const StyledBox = styled(Box)({
    color: '#000',
    fontWeight: 'bold',
    display: "flex",
    justifyContent: 'center',
});


const StyledLink = styled(Link)({
    textDecoration: "none",
    color: '#000',
    fontWeight: '500',
    fontSize: '20px',
    whiteSpace: "nowrap"
});


const SideLink = styled(Link)(
    ({ theme, isActive }) => css`
      text-decoration: none;
      color: #000;
      font-weight: 500;
      opacity: 0.5;
      white-space: nowrap;
      ${isActive &&
        css`
        // Apply specific styles when isLogin is true
        // Add your conditional CSS here
        color: "#000";
        opacity: 1;
      `}
  
      ${theme.breakpoints.up('xl')} {
        font-size: 50px;
      }
      ${theme.breakpoints.up('lg')} {
        font-size: 50px;
      }
      ${theme.breakpoints.down('md')} {
        font-size: 30px;
      }
      ${theme.breakpoints.down('sm')} {
        font-size: 25px;
      }
    `
);

const HomeLink = styled(Link)({
    textDecoration: 'none',
    color: '#000',
    fontSize: '30px',
    fontWeight: '500',

});

const Header = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
        if (!isMenuOpen) {
            document.body.classList.add("menu-open"); // Add your desired class name    
        } else {
            document.body.classList.remove("menu-open"); // Remove the class name
        }
    };

    const menuList = [
        {
            name: "Drivers",
            path: "/drivers"
        },
        {
            name: "Customers",
            path: "/customers"
        },
        {
            name: "Blogs",
            path: "/blogs"
        },
        {
            name: "Brangchain in Media",
            path: "/news"
        },
        // {
        //     name: "Sustainability",
        //     path: "/sustainability"
        // },
        {
            name: "Login",
            path: "https://app.brangchain.com.au/login"
        },
        {
            name: "Register",
            path: "/register"
        },
    ];

    const location = useLocation();
    const registerRoute = location.pathname.includes('register');
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const sideList = side => (
        <Box sx={{
            height: "88.5vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${navbarbg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'fixed',
            top: '0px',
            left: '0px',
            right: '0px',
            height: '100%',
            zIndex: '9999999'
        }}>
            <Box sx={{
                width: "100%"
            }}>
                <Box sx={{
                    textAlign: 'end',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleMenuClick}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <List sx={{
                    maxWidth: 'max-content',
                    width: '100%',
                    margin: '0 auto',
                }}>
                    {menuList.map((menu) => {
                        return (
                            <ListItem key={menu.name} onClick={handleMenuClick}>
                                <SideLink isActive={location.pathname === menu.path} to={menu.path} target={menu.name === "Login" ? "_blank" : ""}>
                                    {menu.name}
                                </SideLink>
                            </ListItem>
                        )
                    })}
                </List>
            </Box >
        </Box>
    );

    return (
        <>
            <Container maxWidth="xl" >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'relative',
                    zIndex: '9999999',
                    top: '0px',
                    marginTop: { xl: '10px', lg: '10px', md: '20px', sm: '20px', xs: '10px' },
                    '& img': {
                        width: "100%",
                        minWidth: '200px',
                        position: 'relative',
                        top: '8px',
                    },
                }} >
                    <HomeLink to="/">
                        <img src={HeaderLogon} />
                    </HomeLink>
                    <StyledBox>
                        <List sx={{
                            display: { lg: "flex", md: 'flex', sm: 'none', xs: 'none' }
                        }}>
                            {menuList.map((menu) => {
                                return (
                                    <ListItem key={menu.name} sx={{
                                        padding: {xl: '8px 25px !important', lg: "8px 16px !important", md: "8px 10px !important"},
                                        '&:last-child': {
                                            paddingRight: '0px',
                                        }
                                    }}>
                                        <StyledLink to={menu.path} target={menu.name === "Login" ? "_blank" : ""}>
                                            {menu.name}
                                        </StyledLink>
                                    </ListItem>
                                )
                            })}
                        </List>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleMenuClick}
                            sx={{
                                display: { xl: 'none', lg: 'none', md: 'none', sm: 'block', xs: 'block' },
                                paddingLeft: '25px',
                                paddingRight: '0px',
                                paddingBottom: '0px',
                                '&:hover': {
                                    background: 'transparent'
                                },
                                '& span': {
                                    display: 'none',
                                }
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </StyledBox>
                </Box>
                {isMenuOpen && sideList()}</Container >
        </>
    );
};

export default Header;