import Header from "./Header";
import TopFooter from "../footer/TopFooter";
import Footer from "../footer/Footer";
import { Box } from "@mui/material";


const Layout = ({ children }) => {

    return (
        <Box>
            <TopFooter />
            <Header />
            {children}
            <Footer />
        </Box>
    )
};

export default Layout;