import pharmacy from "../../img/pharmacy.jpg";
import fashion from "../../img/fashion.png";
import document from "../../img/document.jpg";
import liquor from "../../img/liquor.jpg";
import electronics from "../../img/electronics.jpg";
import florist from "../../img/florist.jpg";
import food from "../../img/food.jpg";




export const data = {
    leadingText: "BrangChain For ",
    slides: [
        {
            introline: "Pharmacy",
            id: "Pharmacy",
            content: {
                image: pharmacy,
                subTitle: "Solution is here",
                description: "We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our company",
            }
        },
        {
            introline: "Fashion",
            id: "Fashion",
            content: {
                image: fashion,
                subTitle: "Solution is here 2",
                description: "We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our company",
            }
        },
        {
            introline: "Florist",
            id: "Florist",
            content: {
                image: florist,
                subTitle: "Solution is here 3",
                description: "We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our company",
            }
        },
        {
            introline: "electronics",
            id: "electronics",
            content: {
                image: electronics,
                subTitle: "Solution is here",
                description: "We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our company",
            }
        },
        {
            introline: "Food",
            id: "Food",
            content: {
                image: food,
                subTitle: "Solution is here",
                description: "We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our company",
            }
        },
        {
            introline: "liquor",
            id: "liquor",
            content: {
                image: liquor,
                subTitle: "Solution is here",
                description: "We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our company",
            }
        },
        {
            introline: "Packages",
            id: "Packages",
            content: {
                image: document,
                subTitle: "Solution is here",
                description: "We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our company",
            }
        },
    ]
}
// {
//     "leadingText": "Brangchain is ",
//     "slides": [
//         {
//             "introline": "Pharmacy",
//             "id": "Pharmacy",
//             "content": {
//                 "image": "https://via.placeholder.com/400x200?text=Dogs",
//                 "copy": "Dog ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan est ornare, ultricies erat a, dapibus lectus."
//             }
//         },
//         {
//             "introline": "Fashion",
//             "id": "Fashion",
//             "content": {
//                 "image": "https://via.placeholder.com/400x200?text=Elephants",
//                 "copy": "Elephant ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan est ornare, ultricies erat a, dapibus lectus."
//             }
//         },
//         {
//             "introline": "Florist",
//             "id": "Florist",
//             "content": {
//                 "image": "https://via.placeholder.com/400x200?text=Bears",
//                 "copy": "Bears ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan est ornare, ultricies erat a, dapibus lectus."
//             }
//         },
//         {
//             "introline": "Document",
//             "id": "Document",
//             "content": {
//                 "image": "https://via.placeholder.com/400x200?text=Lizards",
//                 "copy": "Lizards ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan est ornare, ultricies erat a, dapibus lectus."
//             }
//         },
//         {
//             "introline": "electronics",
//             "id": "electronics",
//             "content": {
//                 "image": "https://via.placeholder.com/400x200?text=Snakes",
//                 "copy": "Snakes ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan est ornare, ultricies erat a, dapibus lectus."
//             }
//         },
//         {
//             "introline": "Food",
//             "id": "Food",
//             "content": {
//                 "image": "https://via.placeholder.com/400x200?text=Cats",
//                 "copy": "Cats ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan est ornare, ultricies erat a, dapibus lectus."
//             }
//         },
//         {
//             "introline": "liquor",
//             "id": "liquor",
//             "content": {
//                 "image": "https://via.placeholder.com/400x200?text=Giraffes",
//                 "copy": "Giraffes ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan est ornare, ultricies erat a, dapibus lectus."
//             }
//         }
//     ]
// }