import maddyAward from "../../img/news-images/maddy_award.jpeg";
import imranAward from "../../img/news-images/imran_award.jpg";
import kabirAward from "../../img/news-images/kabir_award.jpg";
import globaltechoutlookCertificate from "../../img/news-images/globaltechoutlook_certificate.jpg";
import supplyChain from "../../img/news-images/supply-chain.jpg";
import australianBlockchainIndustryAward2023 from "../../img/news-images/australian-blockchain-industry-award-2023.jpg";
import blog17 from "../../img/blogs/blog17.png";
import brangchainAo from "../../img/news-images/brangchainao.png";
import brangchainSpeaker from "../../img/news-images/blockchain-week.png";

export const newsData = [
    {
        title: "BrangChain - Think Delivery. Think Brang: Australian Owned Certified, Licence AO 01955",
        desc: "BrangChain - Think Delivery. Think Brang: Revolutionising Last-Mile Logistics for a Sustainable Future, Their innovative platform, driven by blockchain, AI and smart contracts links all players in last-mile deliveries, offering transparency, security and real-time accountability.",
        image: brangchainAo,
        weblink: "",
        digitalLink: "https://www.linkedin.com/posts/australianowned_officialausowned-australianbusiness-ausowned-ugcPost-7117722077981274112-rQBY?utm_source=share&utm_medium=member_desktop"
    },
    {
        title: "BrangChain - Think Delivery. Think Brang is thrilled to be a finalist for the Blockies Startup or Scale-up of the Year Award this year powered by Blockchain Australia!",
        desc: "Join us in the celebration in Melbourne on November 9th for the Award Ceremony and Rooftop Party at Rydges Ball Room and Rooftop Terrace.",
        image: australianBlockchainIndustryAward2023,
        weblink: "https://www.linkedin.com/posts/brang-think-delivery-think-brang_blockies2023-blockchainaustralia-brangchain-activity-7119517750334566400-a8dl/?utm_source=share&utm_medium=member_desktop",
        digitalLink: ""
    },
    {
        title: "IT Brief Australia - Technology news for CIOs & IT decision-makers",
        desc: "BrangChain has unveiled its latest solution to transform last-mile logistics while prioritising environmental sustainability.With a commitment to preserving our planet for future generations, it aims to revolutionise the way goods are delivered and create a greener, more efficient supply chain.",
        image: supplyChain,
        weblink: "https://itbrief.com.au/story/brangchain-aims-to-make-supply-chains-green-with-latest-launch",
        digitalLink: "",
        moreLink: "https://www.linkedin.com/posts/brang-think-delivery-think-brang_brangchain-aims-to-make-supply-chains-green-activity-7120343363869933568-KM3x?utm_source=share&utm_medium=member_desktop"
    },
    {
        title: "Blockchain Australia Recognizes BrangChain as a Promising Solution in the Last-Mile Delivery Industry",
        desc: "We were delighted to read the review article where BrangChain was mentioned as a promising solution in this rapidly evolving sector. Blockchain Australia's recognition of our efforts to address the challenges faced in the last-mile delivery industry is truly encouraging and motivates us to continue pushing the boundaries of innovation.",
        image: brangchainSpeaker,
        weblink: "",
        digitalLink: "https://www.linkedin.com/posts/brang-think-delivery-think-brang_brangchain-brang-thinkdeliverythinkbrang-activity-7083087570003197952-YuxM?utm_source=share&utm_medium=member_desktop"
    },
    {
        title: "We are very pleased to announce that BRANG - Think Delivery. Think Brang have become a member of Blockchain Australia.",
        desc: "We are excited to announce that Brang has become a member of Blockchain Australia, the peak industry body for businesses implementing or evaluating blockchain or distributed ledger technology in Australia.",
        image: blog17,
        weblink: "https://blockchainaustralia.org/memberslist/",
        digitalLink: ""
    },
    {
        title: "Madhup Mohan Mudiraj: The Man Behind the Innovation of Fastest Delivery",
        desc: "Madhup Mohan Mudiraj, an Indian-Australian business executive, is the CEO and the Co-founder of BRANG. He is a Director at INK IT solutions, a leading technology consultancy, and provider of CX, ERP, and HCM solutions across various industries",
        image: maddyAward,
        weblink: "https://industrywired.com/madhup-mohan-mudiraj-the-man-behind-the-innovation-of-fastest-delivery/",
        digitalLink: "https://industrywired.com/The-10-Most-Influential-CEOs-Rising-to-the-Top-in-2021/#page=28"
    },
    {
        title: "“BRANG” Shakes the Tech Hub with a “BANG”",
        desc: "With so much innovation and modernisation happening, the focus of companies,is to stay on top of the game by utilizing this for betterment of its processes and ultimately make it a key contributor for its success. At BRANG, technology plays",
        image: imranAward,
        weblink: "https://www.globaltechoutlook.com/brang-shakes-the-tech-hub-with-a-bang/",
        digitalLink: ""
    },
    {
        title: "What Can CFOs Do to Change the Tailwind of a Company during Crisis?",
        desc: "It is a matter of fact that strong and steady leadership from the finance organization is critical for addressing immediate concerns about safety and survival, stabilizing the business in the near term, and positioning it for recovery",
        image: kabirAward,
        weblink: "",
        digitalLink: "https://www.globaltechoutlook.com/what-can-cfos-do-to-change-the-tailwind-of-a-company-during-crisis/"
    },
    {
        title: "BRANG: The Easiest Way to Get Things Delivered During the Pandemic",
        desc: "80% of Australians are purchasing things online with ease. However, one consistent complaint is about getting their orders delivered, since the local delivery takes 2-3 days, is expensive, and does not offer 24/7 service",
        image: globaltechoutlookCertificate,
        weblink: "https://www.globaltechoutlook.com/brang-the-easiest-way-to-get-things-delivered-during-the-pandemic/",
        digitalLink: "https://www.globaltechoutlook.com/The-10-Most-Trustworthy-Companies-to-Watch-in-2021/#page=18"
    },
]