import React from 'react'
import { Helmet } from 'react-helmet'

const MetaContainer = ({ title, description, keywords, imageURL, pageURL}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:locale" content="en-US" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Brangchain-think delivery,think Brangchain" />
            <meta property="og:image" content={imageURL} />
            <meta property="og:url" content={pageURL} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageURL} />
            <meta name="twitter:card" content={imageURL} />
            <meta name="twitter:creator" content="Brangchain-think delivery,think Brangchain" />
        </Helmet>
    )
}

export default MetaContainer;