import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box, Grid, Typography } from '@mui/material';
import sliderImageOne from '../../img/branger-slider-img-1.jpg'
import { Link } from 'react-router-dom';
import blogImgOne from '../../img/blog-img-1.jpg'
import blogImgTwo from '../../img/blog-img-2.jpg'
import blogImgThree from '../../img/blog-img-3.jpg'

const BlogList = (props) => {
    const { blogList } = props;

    function TruncateText({ text, maxLength }) {
        if (text.length <= maxLength) {
            return <span>{text}</span>;
        } else {
            const truncatedText = text.slice(0, maxLength) + '...';
            return <span title={text}>{truncatedText}</span>;
        }
    };

    return (
        <Grid container spacing={2} sx={{
            marginBottom: '20px'
        }}>

            {blogList?.length > 0 && blogList.toReversed().map((blog) => {
                return (
                    <Grid key={blog.id} item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Box sx={{
                            boxShadow: '0 1rem 2rem rgba(0,0,0,.175)!important',
                            borderRadius: '10px',
                            height: { xl: '580px', lg: '580px', md: '580px', sm: '480px', xs: 'auto' },
                            overflow: 'auto',
                            position: 'relative'
                        }}>
                            <Box sx={{
                                width: '100%',
                                maxHeight: { xl: '270px', lg: '270px', md: '270px', sm: 'auto', xs: 'auto' },
                                height: { xl: '100%', lg: '100%', md: '100%', sm: 'auto', xs: 'auto' },
                                '& img': {
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    marginBottom: '15px',
                                }
                            }}>
                                <img src={blog.mainImage} />
                            </Box>

                            <Box sx={{
                                padding: '15px',
                            }}>
                                <Box sx={{
                                    display: "flex",
                                    gap: "10px",
                                    flexWrap: 'wrap',
                                }}>
                                    <Typography sx={{
                                        color: "#0046f7"
                                    }}>{blog.published_date}</Typography>
                                    <Typography sx={{
                                        textTransform: "uppercase"
                                    }}>BY: {blog.published_by}</Typography>
                                </Box>
                                <Box variant='h2' sx={{
                                    fontSize: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' },
                                    fontWeight: '500',
                                    margin: { xl: '15px 0px', lg: '15px 0px', md: '10px 0px', sm: '10px 0px', xs: '10px 0px' },
                                    color: '#0046f7',
                                    textTransform: "capitalize",
                                    cursor: "pointer",
                                    "& a": {
                                        textDecoration: "none",
                                        textTransform: "capitalize",
                                    }
                                }}><Link to={`/blogs/${blog.id}`}>
                                        <TruncateText text={blog.title} maxLength={45} /></Link></Box>
                                <Typography sx={{
                                    color: '#000',
                                    opacity: '0.5',
                                    display: '-webkit-box',
                                    maxWidth: '100%',
                                    margin: '0 auto',
                                    '-webkit-line-clamp': '3',
                                    '-webkit-box-orient': 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',

                                }}>{blog.content[0]}</Typography>
                            </Box>
                            <Box sx={{
                                // padding: '15px',
                                borderTop: "1px solid #e9e9ec",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: '5px',
                                position: { xl: 'absolute', lg: 'absolute', md: 'absolute', sm: 'absolute', xs: 'inherit' },
                                bottom: '0px',
                                width: '100%',
                                '& a': {
                                    textDecoration: 'none',
                                    color: '#adadad',
                                    cursor: 'pointer',
                                    paddingLeft: '10px'
                                }
                            }}>
                                <Link to={`/blogs/${blog.id}`}>Read More</Link>
                                <Box sx={{
                                    clipPath: 'polygon(100% 0%, 100% 74%, 100% 100%, 0 100%, 30% 0, 25% 0%)',
                                    color: '#fff',
                                    paddingLeft: '20px',
                                    bottom: '0',
                                    right: '0',
                                    width: '50px',
                                    height: '50px',
                                    display: 'inline-block',
                                    backgroundColor: '#0046f7',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer'
                                }}>
                                    <Link to={`/blogs/${blog.id}`}>
                                        <ArrowRightAltIcon />
                                    </Link>
                                </Box>

                            </Box>
                        </Box>
                    </Grid>
                )
            })
            }
        </Grid>
    )
}

export default BlogList
