import { Box, Fade, Typography } from "@mui/material";
import Layout from "../layout/Layout";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from "react";
import Globe from 'react-globe.gl';
import globeImage from "../../img/globe_texture_light_v10.png"
import { useRef } from "react";
import { useEffect } from "react";
import appImage from "../../img/apple-logo.svg"
import MetaContainer from "../../seo/MetaContainer";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Fade in={value === index} timeout={3000}>
                <Box sx={{ p: 3, }}>
                    <Box>{children}</Box>
                </Box>
            </Fade>
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const Chapter = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const globeRef = useRef(null);
    // const MAP_CENTER = { lat: -37.8523534, lng: 144.9187178, altitude: 1.6 };
    const MAP_CENTER = { lat: -37.9725665, lng: 145.0531353, altitude: 1 };


    useEffect(() => {
        if (globeRef.current) {
            // Start autorotation on component mount
            // globeRef.current.controls().autoRotate = true;
            // globeRef.current.controls().autoRotateSpeed = 0.4;
            globeRef.current.pointOfView(MAP_CENTER, 5000);
        }
    }, []);


    const data = [
        {
            label: "Australia",
            startlat: -37.7997687,
            startlng: 144.9539031,
            endlat: -38.020312,
            endlng: 145.2660747,
            color: ["#0090ff", "#0090ff"],
            stroke: 0.01
        },
        {
            label: "Australia",
            startlat: -37.7997687,
            startlng: 144.9539031,
            endlat: -37.8261097,
            endlng: 145.2614337,
            color: ["#0090ff", "#0090ff"],
            stroke: 0.01
        },
        {
            label: "Australia",
            startlat: -37.7997687,
            startlng: 144.9539031,
            endlat: -37.8462603,
            endlng: 145.2421828,
            color: ["#0090ff", "#0090ff"],
            stroke: 0.01
        }, {
            label: "Australia",
            startlat: -37.7997687,
            startlng: 144.9539031,
            endlat: -37.8098712,
            endlng: 145.2182749,
            color: ["#0090ff", "#0090ff"],
            stroke: 0.01
        }, {
            label: "Australia",
            startlat: -37.7997687,
            startlng: 144.9539031,
            endlat: -38.112701,
            endlng: 145.231122,
            color: ["#0090ff", "#0090ff"],
            stroke: 0.01
        }, {
            label: "Australia Sydney",
            startlat: -33.6971888,
            startlng: 151.0799517,
            endlat: -33.9214331,
            endlng: 150.8959479,
            color: ["#0090ff", "#0090ff"],
            stroke: 0.01
        }, {
            label: "Australia Sydney",
            startlat: -33.9214331,
            startlng: 150.8959479,
            endlat: -33.8814627,
            endlng: 151.2049173,
            color: ["#0090ff", "#0090ff"],
            stroke: 0.01
        }, {
            label: "Australia Sydney",
            startlat: -33.9214331,
            startlng: 150.8959479,
            endlat: -33.6919964,
            endlng: 150.5335871,
            color: ["#0090ff", "#0090ff"],
            stroke: 0.01
        }, {
            label: "Australia Adelaide",
            startlat: -34.8361389,
            startlng: 138.6743066,
            endlat: -34.5985439,
            endlng: 138.7334794,
            color: ["#0090ff", "#0090ff"],
            stroke: 0.01
        }, {
            label: "Australia Adelaide",
            startlat: -34.8361389,
            startlng: 138.6743066,
            endlat: -34.5985439,
            endlng: 138.7334794,
            color: ["#0090ff", "#0090ff"],
            stroke: 0.01
        }, {
            label: "Australia Adelaide",
            startlat: -35.1207809,
            startlng: 138.5066022,
            endlat: -34.5985439,
            endlng: 138.7334794,
            color: ["#0090ff", "#0090ff"],
            stroke: 0.01
        },
    ];


    const pointsData = data.map(item => ({
        lat: item.startlat,
        lng: item.startlng,
        color: item.color[0],
        label: `Start - ${item.label}`,
        size: 0.02,
        enableGlow: true,
        glowCoefficient: 0.5,
        glowPower: 5,
        stroke: item.stroke,
        image: item.startImage, // Add the start point image
    })).concat(data.map(item => ({
        lat: item.endlat + (Math.random() - 0.5) * 0.001,
        lng: item.endlng + (Math.random() - 0.5) * 0.001,
        color: "white",
        label: `End - ${item.label}`,
        size: 0.02,
        enableGlow: true,
        glowCoefficient: 0.5,
        glowPower: 5,
        image: appImage, // Add the end point image
    })));


    // const startLocations = data.map((item) => ({
    //     lat: item.startlat,
    //     lng: item.startlng,
    //     color: item.color[0],
    //     label: `Start - ${item.label}`,
    //     size: 0.02, // Initial size of the point
    //     enableGlow: true, // Enable glow effect for the point
    //     glowCoefficient: 0.5, // Adjust the glow intensity
    //     glowPower: 5, // Adjust the glow spread
    // }));
    //
    // const endLocations = data.map((item, index) => ({
    //     lat: item.endlat,
    //     lng: item.endlng ,
    //     color: "white", // Use a different color for each point
    //     label: `End - ${item.label}`,
    //     size: 0.02, // Initial size of the point
    //     enableGlow: true, // Enable glow effect for the point
    //     glowCoefficient: 0.5, // Adjust the glow intensity
    //     glowPower: 5, // Adjust the glow spread
    // }));

    // const pointsData = [...startLocations, ...endLocations];

    return (
        <Layout>
            <MetaContainer
                title='Chapter - Brangchain-think delivery,think Brangchain'
                description='Brangchain is a delivery platform providing same day delivery service all across Australia currently in Melbourne. It is contact less, instant, reliable, insured , 24 / 7 delivery platform taking care of all your delivery needs.'
                keywords='Delivery,click and collect delivery,same day delivery,pharmacy delivery,instant delivery,aged care,delivery partner,stores delivery,essentials ,australia,melbourne,24x7,reliable,superfast,insured,contactless delivery, BrangChain, Brangchain, think delivery'
                imageURL='https://careers.inkitsolutions.com.au/media/images/image_2024_08_22T09_17_21_722Z.png'
                pageURL='https://brangchain.com.au/chapter'
            />
            <Box sx={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                marginTop: '-66px',
            }}>
                <Box sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    // background: '#ccc'
                }}>
                    <Globe
                        ref={globeRef}
                        globeImageUrl={globeImage}
                        height="100%"
                        width="100%"
                        ambientLightColor='blue'
                        backgroundColor="#fff"
                        // arcsData={data}
                        arcStartLat={(d) => +d.startlat}
                        arcStartLng={(d) => +d.startlng}
                        arcEndLat={(d) => +d.endlat}
                        arcEndLng={(d) => +d.endlng}
                        arcDashLength={1}
                        arcDashGap={1}
                        arcLabel={(d) => +d.label}
                        arcDashInitialGap={() => Math.random()}
                        arcDashAnimateTime={4000}
                        arcColor={(d) => d.color}
                        arcStroke={(d) => d.stroke}
                        arcsTransitionDuration={0}


                        // pointsData={pointsData}
                        pointColor={() => 'black'}
                        // pointAltitude={0.0001}
                        pointRadius={0.05}
                        pointsMerge={true}
                        pointAltitude={0.00001}
                    />
                </Box>
                <Box sx={{ width: { lg: 'calc(100% - 100px)', md: 'calc(100% - 100px)', sm: 'calc(100% - 100px)', xs: '100%', }, position: 'absolute', bottom: { lg: '50px', md: '50px', sm: '50px', xs: '0px', }, left: { lg: '50px', md: '50px', sm: '50px', xs: '0px', }, zIndex: '0', pointerEvents: 'none', display: "flex", flexDirection: 'column', justifyContent: { lg: 'space-between', md: 'space-between', sm: 'flex-end', xs: 'flex-end', }, height: '100%', }}>
                    <TabPanel value={value} index={0}>
                        <Box sx={{
                            marginTop: { lg: '180px', md: '180px', sm: '100px', xs: '0px', },
                        }}>
                            <Fade in={true} timeout={1000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Brangchain is world's first last mile sustainable delivery services using blockchain.</Typography>
                            </Fade>
                            <Fade in={true} timeout={2000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Provides a full proof of delivery and real time ETA updates.</Typography>
                            </Fade>
                            <Fade in={true} timeout={3000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Uses the power of blockchain, AI and advance route optimisation.</Typography>
                            </Fade>
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box sx={{
                            marginTop: { lg: '180px', md: '180px', sm: '100px', xs: '0px', },
                        }}>
                            <Fade in={true} timeout={1000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Orders are put on the Brangchain network by merchants from industries like pharmacy, retail etc.
                                </Typography>
                            </Fade>
                            <Fade in={true} timeout={2000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Based on AI, orders are distributed to Brang drivers who are fully compliant with 100 points check.
                                </Typography>
                            </Fade>
                            <Fade in={true} timeout={3000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>The first driver to accept the job, gets to deliver.</Typography>
                            </Fade>
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Box sx={{
                            marginTop: { lg: '180px', md: '180px', sm: '100px', xs: '0px', },
                        }}>
                            <Fade in={true} timeout={1000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Using advance routing mechanism, reducing carbon emission up to 25%, drivers receive the order on their mobile devices</Typography>
                            </Fade>
                            <Fade in={true} timeout={2000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Driver needs to provide a proof to the store when picking up the order, ensuring orders are given to right driver</Typography>
                            </Fade>
                            <Fade in={true} timeout={3000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Real time ETAs are sent to the customers with tracking links
                                </Typography>
                            </Fade>
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Box sx={{
                            marginTop: { lg: '180px', md: '180px', sm: '100px', xs: '0px', },
                        }}>
                            <Fade in={true} timeout={1000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Driver needs to provide a proof to the store when picking up the order, ensuring orders are given to right driver
                                </Typography>
                            </Fade>
                            <Fade in={true} timeout={1000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Real time ETAs are sent to the customers with tracking links
                                </Typography>
                            </Fade>
                            <Fade in={true} timeout={1000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Customer needs to provide the scan code to the driver to receive the delivery, and the transaction is stored on the blockchain network.
                                </Typography>
                            </Fade>
                            <Fade in={true} timeout={1000}>
                                <Typography sx={{ fontSize: { lg: '22px', md: '22px', sm: '18px', xs: '18px', }, fontWeight: '400', lineHeight: { lg: '30px', md: '30px', sm: '22px', xs: '22px', }, color: '#000000', textTransform: 'inherit', marginBottom: '15px', }}>Finally , BC02 (carbon emission) tokens are distributed to the driver, store and the customer as part of sustainable initiative by Brangchain. Driver captures proof of delivery and gets ready for the next job.
                                </Typography>
                            </Fade>
                        </Box>
                    </TabPanel>
                    <Box sx={{
                        '.MuiTabs-indicator': {
                            display: 'none',
                        },
                        '.MuiTouchRipple-root': {
                            display: 'none',
                        },
                        '.MuiButtonBase-root': {
                            opacity: '0.6',
                            transition: '0.5s ease',
                            '&:hover': {
                                opacity: '1',
                            },
                        },
                        '.Mui-selected': {
                            opacity: '1',
                        },
                    }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{
                            '& .MuiTabs-flexContainer': {
                                flexWrap: 'nowrap',
                                overflowX: 'auto',
                            },
                            "& .MuiTab-root": {
                                textTransform: "none"
                            },
                            position: 'relative',
                            zIndex: '999',
                            pointerEvents: 'all',
                            marginBottom: '50px',
                        }}>
                            <Tab label={
                                <Box sx={{
                                    textAlign: 'left',
                                }}>
                                    <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: '24px', color: '#000', marginBottom: '10px', }}>Chapter 1</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '400', lineHeight: '20px', color: '#000', }}>What is BrangChain?</Typography>
                                </Box>
                            } {...a11yProps(0)} />
                            <Tab label={
                                <Box sx={{
                                    textAlign: 'left',
                                }}>
                                    <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: '24px', color: '#000', marginBottom: '10px', }}>Chapter 2</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '400', lineHeight: '20px', color: '#000', }}>How does it work with merchants?</Typography>
                                </Box>
                            } {...a11yProps(1)} />
                            <Tab label={
                                <Box sx={{
                                    textAlign: 'left',
                                }}>
                                    <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: '24px', color: '#000', marginBottom: '10px', }}>Chapter 3</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '400', lineHeight: '20px', color: '#000', }}>How does it work with drivers?</Typography>
                                </Box>
                            } {...a11yProps(2)} />
                            <Tab label={
                                <Box sx={{
                                    textAlign: 'left',
                                }}>
                                    <Typography sx={{ fontSize: '20px', fontWeight: '600', lineHeight: '24px', color: '#000', marginBottom: '10px', }}>Chapter 4</Typography>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '400', lineHeight: '20px', color: '#000' }}>How does it work with customers?</Typography>
                                </Box>
                            } {...a11yProps(3)} />
                        </Tabs>
                    </Box>

                </Box>

            </Box>
        </Layout >
    )
};

export default Chapter;