import { Box } from "@mui/material";
import Layout from "../layout/Layout";
import { data } from "./data";
import VerticalSlider from "./VerticalSlider";
import MetaContainer from "../../seo/MetaContainer";

const Customers = () => {
    return (
        <Layout>
            <MetaContainer 
                title='Customer - Brangchain-think delivery,think Brangchain'
                description='Brangchain is a delivery platform providing same day delivery service all across Australia currently in Melbourne. It is contact less, instant, reliable, insured , 24 / 7 delivery platform taking care of all your delivery needs.'
                keywords='Delivery,click and collect delivery,same day delivery,pharmacy delivery,instant delivery,aged care,delivery partner,stores delivery,essentials ,australia,melbourne,24x7,reliable,superfast,insured,contactless delivery, BrangChain, Brangchain, think delivery'
                imageURL='https://careers.inkitsolutions.com.au/media/images/image_2024_08_22T09_17_21_722Z.png'
                pageURL='https://brangchain.com.au/customers'
            />
            <VerticalSlider data={data?.slides} leadingText={data?.leadingText} />
        </Layout>
    )
};

export default Customers;