import { Box, Container, Link, Typography, Grid } from '@mui/material'
import React from 'react'
import brangdetail from '../../img/brangblog-detail-img.jpg'
import Layout from '../layout/Layout'
import BlogCategories from './BlogCategories'
import { useParams } from 'react-router-dom'
import { blogList } from './blogData'

const BlogDetailsPage = () => {
    const { id } = useParams()
    const blog = blogList.filter((blog) => Number(blog.id) === Number(id))[0];

    return (
        <Layout>
            <Container maxWidth="xxl">
                <Box sx={{
                    marginTop: { xl: '50px', lg: '50px', md: '50px', sm: '40px', xs: '30px' }
                }}>
                    {/* <Typography sx={{
                        color: '#000',
                        opacity: '0.5',
                        maxWidth: { xl: '800px', lg: '800px', md: '800px', sm: '100%', xs: '100%' },
                        width: '100%',
                        margin: "0 auto"
                    }}>We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our compan We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our compan</Typography>
                    <Box sx={{
                        width: '-webkit-fill-available',
                        height: '100%',
                        backgroundImage: `url(${blogImg})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        padding: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' },
                        borderRadius: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' },
                        marginTop: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' },
                        height: '400px',
                        position: 'relative',
                        marginBottom: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' }
                    }}>
                        <Box sx={{
                            maxWidth: { xl: '500px', lg: '500px', md: '500px', sm: '500px', xs: '90%' },
                            width: '100%',
                            position: 'absolute',
                            bottom: '30px',
                            // wordBreak: 'break-all'
                        }}>
                            <Typography sx={{
                                fontSize: { xl: '40px', lg: '30px', md: '30px', sm: '30px', xs: '25px' },
                                color: '#fff',
                                textTransform: 'capitalize',
                                marginBottom: '15px',
                            }}>Use Brangchain To deliver your products same day</Typography>
                            <Typography sx={{
                                color: '#fff',
                                opacity: '0.5'
                            }}>We are looking for a responsible Delivery Driver to distribute products promptly to our customers, using our platform. You will represent our compan</Typography>
                        </Box>
                    </Box> */}
                </Box>
            </Container>
            <Container maxWidth="xxl" sx={{
                maxWidth: { xl: '1200px', }
            }}>
                <Grid container spacing={3}>
                    <Grid item xl={9} lg={9} md={9} sm={9} xs={12} >
                        <Box sx={{
                            maxWidth: '880px',
                            width: "100%",
                            margin: '0 auto',
                            position: { xl: 'relative', lg: 'relative', md: 'relative', sm: 'inherit', xs: 'inherit' },
                            '& img': {
                                width: "100%"
                            }
                        }}>
                            <img src={blog?.mainImage} />
                            <Box sx={{
                                maxWidth: { xl: '820px', lg: '820px', md: '650px', sm: '470px', xs: '-webkit-fill-available' },
                                width: "100%",
                                backgroundColor: '#f3f3f3',
                                padding: "20px",
                                // position: { xl: 'absolute', lg: 'absolute', md: 'absolute', sm: 'inherit', xs: 'inherit' },
                                // top: '380px'
                            }}>
                                <Box sx={{
                                    marginBottom: '15px',
                                    '& a': {
                                        textDecoration: 'none',
                                        color: '#0046f7',
                                        cursor: 'pointer',
                                        marginRight: '10px',
                                    }
                                }}>
                                    <Link href="">{blog?.published_date}</Link>
                                    <span>BY: {blog?.published_by}</span>
                                </Box>
                                <Typography variant='h2' sx={{
                                    fontSize: { xl: '30px', lg: '30px', md: '30px', sm: '25px', xs: '25px' },
                                    fontWeight: '600',
                                    marginBottom: '20px',
                                    textTransform: "capitalize !important"
                                }}>{blog?.title}</Typography>
                                {blog?.content?.map((item) => {
                                    return (
                                        <Typography sx={{
                                            fontSize: '17px',
                                            color: '#333333',
                                            fontWeight: '500',
                                            marginBottom: "10px",
                                            lineHeight: "30px",
                                        }}>{item}</Typography>
                                    )
                                })}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={3} xs={12} >
                        <BlogCategories />
                    </Grid>
                </Grid>
            </Container>
        </Layout >
    )
}

export default BlogDetailsPage;



