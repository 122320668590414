import React from 'react'
import Layout from '../layout/Layout'
import { Box, Container, Link, Typography } from '@mui/material';
import { newsData } from './newsData';
import MetaContainer from '../../seo/MetaContainer';

const News = () => {
    return (
        <Layout>
            <MetaContainer
                title='News - Brangchain-think delivery,think Brangchain'
                description='Brangchain is a delivery platform providing same day delivery service all across Australia currently in Melbourne. It is contact less, instant, reliable, insured , 24 / 7 delivery platform taking care of all your delivery needs.'
                keywords='Delivery,click and collect delivery,same day delivery,pharmacy delivery,instant delivery,aged care,delivery partner,stores delivery,essentials ,australia,melbourne,24x7,reliable,superfast,insured,contactless delivery, BrangChain, Brangchain, think delivery'
                imageURL='https://careers.inkitsolutions.com.au/media/images/image_2024_08_22T09_17_21_722Z.png'
                pageURL='https://brangchain.com.au/news'
            />
            <Box sx={{
                margin: '40px 0',
            }}>
                <Container maxWidth="md">
                    {newsData?.map((item, index) => {
                        return (
                            <Box sx={{
                                display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'block' },
                                border: "1px solid #c0bfc0",
                                boxShadow: "0px 0px 8px #cccccc",
                                padding: "20px",
                                marginBottom: '20px',
                                justifyContent: "center",
                            }}>
                                <Box sx={{
                                    height: "180px",
                                    width: { xl: '260px', lg: '260px', md: '260px', sm: '260px', xs: '100%' },
                                    flex: { xl: '0 0 260px', lg: '0 0 260px', md: '0 0 260px', sm: '0 0 260px', xs: '0 0 100%' },
                                    marginRight: '15px',
                                    "& img": {
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain"
                                    }
                                }}> <img src={item.image} /></Box>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    <Box>
                                        <Typography sx={{ marginBottom: "10px", fontWeight: '600', color: "#0046f7" }}>{item.title}</Typography>
                                        <Box sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            "-webkit-line-clamp": '3',
                                            "-webkit-box-orient": 'vertical',
                                        }}>{item.desc}</Box>
                                    </Box>
                                    <Box sx={{
                                        marginTop: '25px',
                                    }}
                                    >{item.weblink && <Link sx={{
                                        marginRight: '10px',
                                        padding: '10px 30px',
                                        background: "blue",
                                        color: "white",
                                        borderRadius: "10px",
                                        textDecoration: "none",
                                        display: "inline-block",
                                    }} href={item.weblink} target="_blank">Weblink</Link>}
                                        {item.digitalLink && <Link sx={{
                                            padding: '10px 30px',
                                            background: "black",
                                            color: "white",
                                            borderRadius: "10px",
                                            textDecoration: "none",
                                            display: "inline-block"
                                        }} target="_blank" href={item.digitalLink}>DigitalLink</Link>}
                                        {item.moreLink && <Link sx={{
                                            padding: '10px 30px',
                                            background: "black",
                                            color: "white",
                                            borderRadius: "10px",
                                            textDecoration: "none",
                                            display: "inline-block"
                                        }} target="_blank" href={item.moreLink}>Visit linkedin for more</Link>}
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}
                </Container>
            </Box >
        </Layout >
    )
}

export default News;
