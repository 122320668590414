import React from 'react'
import { Box, Container, Link, List, ListItem, Typography } from '@mui/material'
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useLocation } from 'react-router-dom';

const TopFooter = () => {
    const location = useLocation();
    const registerRoute = location.pathname.includes('register');
    return (
        <Container maxWidth="xl">
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'space-between',
                borderBottom: '1px solid #eee',
                padding: { xl: '0px', lg: '0px', md: '0px', sm: "12px 0px", xs: '12px 0px' }
            }}>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    gap: '10px',
                    zIndex: registerRoute ? 999 : 1,
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                        cursor: 'pointer',
                        '&:hover': {
                            '& svg': {
                                fill: "#0046f7"
                            },
                        },
                        '& a': {
                            textDecoration: 'none',
                            color: "#000",
                            fontWeight: '500',
                            '&:hover': {
                                color: "#0046f7"
                            },

                        },
                    }}>
                        <CallIcon />
                        <Link href='tel:1300 027 264'>13000BRANG</Link>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                        cursor: 'pointer',
                        '&:hover': {
                            '& svg': {
                                fill: "#0046f7"
                            },
                        },

                        '& a': {
                            textDecoration: 'none',
                            color: "#000",
                            fontWeight: '500',
                            '&:hover': {
                                color: "#0046f7"
                            },

                        },

                    }}>
                        <MailIcon />
                        <Link href='mailto:connect@brang.com.au'> connect@brang.com.au</Link>
                    </Box>
                </Box>
                <Box>
                    <List sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px 0px',
                        '& li': {
                            maxWidth: 'max-content',
                            cursor: 'pointer',
                            padding: { xl: '10px 16px', lg: '10px 16px', md: '10px 16px', sm: '10px 16px', xs: '10px 10px' },
                            '&:last-child': {
                                paddingRight: '0px'
                            },
                            '&:first-child': {
                                paddingLeft: { xl: '10px', lg: '10px', md: '10px ', sm: '0', xs: '0' },
                            }
                        }

                    }}>
                        <ListItem sx={{
                            '&:hover': {
                                '& svg': {
                                    fill: "#0046f7"
                                },
                            },
                            '& a': {
                                display: 'flex'
                            }
                        }}>
                            <Link href="https://www.facebook.com/thinkbrang/" target="_blank" color="#000"><FacebookIcon /></Link>
                        </ListItem>
                        <ListItem sx={{
                            '&:hover': {
                                '& svg': {
                                    fill: "#0046f7"
                                },
                            },
                            '& a': {
                                display: 'flex'
                            }
                        }}><Link href="https://www.linkedin.com/company/brang-think-delivery-think-brang/" target="_blank" color="#000"><LinkedInIcon /></Link>
                        </ListItem>
                        <ListItem sx={{
                            '&:hover': {
                                '& svg': {
                                    fill: "#0046f7"
                                },
                            },
                            '& a': {
                                display: 'flex'
                            }
                        }}><Link href="https://www.instagram.com/thinkbrang/" target="_blank" color="#000"><InstagramIcon /></Link>
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </Container>
    )
}

export default TopFooter
